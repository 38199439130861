import Layout from "../bits/Layout";
import { Rect, App, SideBySide } from '../bits/sharedStyles';

const Page = () => (<Layout still wide>
  <SideBySide>
    <App title="A day in Hong Kong" fullBleed>
      <iframe src="https://player.vimeo.com/video/703322430?h=9062f8ee8a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="296" height="640" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="A day in Hong Kong"></iframe>
    </App>
    <div>
      <Rect>
        <p>Every time I return to Hong Kong, it feels like a whole new city. So much change in such a short time. This art piece uses photos from my last trip, where I wander around for a day. During the 5-hour hackathon sponsored by <a href="https://artivive.com/" target="_blank" rel="noreferrer">Artivive</a>, I created a video collage of the slice of life moments captured that day. The platform recognizes trigger images and will play the content you've associated with the image. It was fun playing with new tools and stretching my imagination to develop a simple project under the timeframe. The video was quickly put together <a href="https://principle.app/" target="_blank" rel="noreferrer">Principle</a>. (Forgive my amateur video editing skills)</p>
      </Rect>
    </div>
  </SideBySide>
</Layout>);

export default Page;