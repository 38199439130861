import React, {useEffect} from "react"
import Reveal from 'reveal.js';
// import RevealNotes from 'reveal.js/plugin/notes/notes';
import PropTypes from "prop-types"

import "reveal.js/dist/reveal.css";

const SlideShow = ({children}) => {
    useEffect(() => {
            const deck = new Reveal({});
            deck.initialize({
                hash: true,
                progress: true,
                plugins: []});
    });

    return (<div style={{width: "100vw", height: "100vh"}}>
            <div className="reveal">
                <div className="slides">{children}</div>
            </div></div>);
}

SlideShow.propTypes = {
    children: PropTypes.node.isRequired,
}

export default SlideShow;