import { StyleSheet, css } from 'aphrodite/no-important';

import {sizes,} from '../bits/sharedStyles';
import ContactCard from './ContactCard';

import {MarkGithubIcon} from '@primer/octicons-react';

const styles = StyleSheet.create({
    footer: {
      padding: `${sizes.pad}px`,
      paddingTop: `${sizes.sectionSpace}px`,
      background: 'var(--color-dark)',
      position: "relative",
      bottom: 0,
  
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
  
      "@media (min-width: 768px)": {
        padding: `${sizes.sectionSpace}px`,
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "flex-start",
      },

      "@media print": {
        display: "none"
      }
    },
    socialList: {
      listStyle: "none",
      fontSize: "24px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 0,
      marginBottom: `${sizes.pad}px`,
      marginLeft: `${sizes.pad + 24}px`,
      marginRight: `${sizes.pad}px`,
  
      ":nth-child(1n) li": {
        marginBottom: 0,
        marginLeft: `${sizes.pad}px`,
        height: "24px",
        width: "24px",
        verticalAlign: "middle",
        position: "relative"
      },
  
      ":nth-child(1n) li > a > svg": {
        position: "absolute",
        top: "-0.4px",
      },

      "@media (min-width: 768px)": {
        marginBottom: 0,
      }
    },
    socialLabel: {
      color: 'var(--color-light)',
      fontSize: "0.7em",
      lineHeight: "24px",
      marginLeft: "-40px",
      fontFamily: "var(--font-body)"
    },
    socialLink : {
      textDecoration: "none",
      color: 'var(--color-primary)',
      ":visited": {
          color: 'var(--color-primary)',
      },
      ":hover": {
        color: 'var(--color-accent)'
      }
    },
    schedule: {
      color: 'var(--color-light)',
      marginLeft: `${sizes.pad}px`
    }
  })

const footer = () => (<footer className={css(styles.footer)} >
  <div>
<ul className={css(styles.socialList)}>
  <span className={css(styles.socialLabel)}>Find me</span>
  <li><a href="https://github.com/9ae"
    target="_blank" rel="noreferrer" className={css(styles.socialLink)}><MarkGithubIcon size={24} /></a></li>
  <li><a href="https://www.linkedin.com/in/aliceqwong/"
    target="_blank"  rel="noreferrer" className={css(styles.socialLink)}><span className="social_linkedin_circle" /></a></li>
  <li><a href="https://dribbble.com/9ae"
    target="_blank"  rel="noreferrer" className={css(styles.socialLink)}><span className="social_dribbble_circle" /></a></li>
</ul>
<p><a href="https://calendly.com/aliceqw/meet" target="_blank"  rel="noreferrer" className={css(styles.socialLink)}>
  <span className="icon_calendar" /> 
  <span className={css(styles.schedule)}>&nbsp;Schedule a 30-min chat with me</span></a></p>
</div>
<ContactCard labelColor='var(--color-accent)' valueColor='var(--color-light)' />
</footer>);

export default footer;