import {useState} from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useNavigate } from "react-router-dom";

import Layout from "../bits/Layout";
import { buttonStyle, styles, sizes } from "../bits/sharedStyles";

const s = StyleSheet.create({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        gap: `${2*sizes.pad}px`,
        width: "500px",
        margin: `${sizes.sectionSpace}px auto`,

        "@media (min-width: 768px)": {
            gap: `${sizes.pad}px`,
        }
    },
    formLine: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: `${sizes.pad}px`,
        "@media (min-width: 768px)": {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "baseline",
        }
      },
    fieldNormal: {
        width: `calc(100% - ${3*sizes.pad}px)`,
        "@media (min-width: 768px)": {
            width: "30ch",
        }
    },
    textarea: {
        width: `calc(100% - ${2.5*sizes.pad}px)`,
        height: "50px",
        marginTop: `${sizes.pad}px`
    }
})

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }


const Field = ({id, label, value, setter, required}) => (<div className={css(s.formLine)}>
    <label htmlFor={id}>{label}{required && <font color='var(--color-primary)'>*</font>}</label>
    <input id={id} name={id} value={value} onChange={(e) => {setter(e.target.value)}} type="text" className={css(styles.input, s.fieldNormal)} required={required} />
</div>);

const Page = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [link, setLink] = useState('');
    const [msg, setMsg] = useState('');

    const goto = useNavigate();

    const handleSubmit = (e) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "request", name, email, company, link, msg })
        })
          .then(() => { goto('/request/ok') })
          .catch(error => console.error(error));
  
        e.preventDefault();
      };
    
    return(<Layout>
    <p>To get see to password protected case studies, fill out this form and I'll get back to you as soon as possible. Fields marked with <font color='var(--color-primary)'>*</font> are required.</p>
    <form className={css(s.form)}>
    <Field id="name" label="Full name" value={name} setter={setName} required />
    <Field id="email" label="E-mail" value={email} setter={setEmail} required />
    <Field id="company" label="Company" value={company} setter={setCompany} />
    <Field id="linkedin" label="LinkedIn URL" value={link} setter={setLink} />
    <div style={{marginTop: `${sizes.pad}px`}}>
        <label htmlFor='msg'>Why are you interested?</label><br />
        <textarea id="msg" name="msg" onChange={(e) =>{setMsg(e.target.value)}} value={msg} className={css(styles.input, s.textarea)} />
    </div>
    <button type="button" onClick={handleSubmit} className={buttonStyle}>Request Access</button>
</form></Layout>)};
export default Page;