import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';

import { sizes, BaseRect, BaseApp } from "./sharedStyles";

import PropTypes from 'prop-types';

/**
 * $black1: #181818;
$black2: #282828;
$black3: #383838;

$grey1: #484848;
$grey2: #585858;
$grey3: #b8b8b8;

$white1: #d8d8d8;
$white2: #e8e8e8;
$white3: #f8f8f8;

$red: #ab4642;
$orange: #dc9656;
$yellow: #f7ca88;
$green: #a1b56c;
$cyan: #86c1b9;
$blue: #7cafc2;
$purple: #b18bba;
$pink: #d5839d;
 */

export const appBgColor = '#484848';
const lightBG = "#efeff4"
const white2 = '#d8d8d8';
const white1 = '#e8e8e8';
const white0 = '#f8f8f8';

const black0 = '#383838';

const purple = '#b18bba';
const blue = '#7cafc2';
const cyan = '#86c1b9';
const yellow = '#f7ca88'
// const pink = '#d5839d';

export const styles = StyleSheet.create({
    bgDark: {
        backgroundColor: appBgColor,
        color: white1,
    },
    bgPurple: {
        backgroundColor: purple,
        color: white0,
    },
    bgBlue: {
        backgroundColor: blue,
        color: white0,
    },
    bgCyan: {
        backgroundColor: cyan,
        color: black0,
    },
    bgYellow: {
        backgroundColor: yellow,
        color: black0,
    },
    bgBlack: {
        backgroundColor: black0,
        color: white2
    },
    bottomBorders: {
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid',
    },
    purpleBorder: {
        borderColor: purple,
    },
    whiteBorders: {
        borderColor: white2
    },
    blueText: {
        color: blue
    },
    yellowText: {
        color: yellow
    },
    cyanText: {
        color: cyan
    },
    purpleText: {
        color: purple
    },
    appWindow: {
        backgroundColor: appBgColor,
        color: white1,
        border: `2px solid ${purple}`,
        boxShadow: `${sizes.pad}px ${sizes.pad}px 0 ${purple}, ${sizes.pad}px ${sizes.pad}px 0 2px ${black0}`
    },
    appTitle: {
        fontSize: "1.2em"
    }
});

export const App = ({ children, title, isShow }) => (
    <BaseApp
        title={title}
        windowStyles={[styles.appWindow]}
        titleStyles={[styles.bgBlack, styles.bottomBorders, styles.purpleBorder, !isShow && styles.appTitle]}
        dotColors={[styles.bgBlue, styles.bgYellow, styles.bgCyan]}
    >{children}</BaseApp>);

export const Rect = ({ children, special, invert, style }) => {
    let accent = special ? blue : cyan;
    let base = black0;

    let newStyle = {
        ...style,
        backgroundColor: invert ? accent : base,
        color: invert ? black0 : white0,
        border: `2px solid ${black0}`,
        boxShadow: `${sizes.pad}px ${sizes.pad}px 0 ${invert ? base : accent}, 
            ${sizes.pad}px ${sizes.pad}px 0 2px ${black0}`
    };
    return (<BaseRect
        style={newStyle}>{children}</BaseRect>)
}

export const Hl = ({ children }) => (<font color={yellow}>{children}</font>);

export const DarkSection = (props) => {
    const { children, light, ...otherProps } = props;

    return (<section
        data-background-color={`${light ? lightBG : appBgColor}`}
        data-background-image={`${process.env.PUBLIC_URL}/lynx_slides_bg_grid.png`}
        data-background-repeat="repeat"
        data-background-size="30px 30px"
        className={light ? "light" : "dark"}
        {...otherProps}>{children}</section>);
}

DarkSection.propTypes = {
    children: PropTypes.node.isRequired,
    light: PropTypes.bool
};

DarkSection.defaultProps = {
    light: false
}