import * as React from 'react';
// import { StyleSheet, css } from 'aphrodite/no-important';

import Notes from "../bits/Notes";

import { App as AppBase, Rect, DarkSection } from '../bits/darkSlides';
import { SideBySide } from '../bits/sharedStyles';

// const s = StyleSheet.create({
// })

const Image = ({ file, alt, center, ...props }) => {

  const image = (<img
    src={`${process.env.PUBLIC_URL}/darkflow/${file}`}
    alt={alt} {...props} />);

  if (center) {
    return (<center>{image}</center>);
  }

  return image;
};

const content = ({ isSlideShow }) => {
  const App = (prop) => (<AppBase isShow={isSlideShow} {...prop} />);

  // TODO change send me to another reward
  return (<>
    <DarkSection
      data-background-image={`${process.env.PUBLIC_URL}/darkflow/nyc.jpeg`}
      data-background-position="bottom center -200px"
      data-background-color="#010101"
      data-background-size="cover"
      data-background-opacity="0.3">
      <Rect special><h1 style={{ color: 'white' }}>Navigate your codebase</h1></Rect>
    </DarkSection>
    <DarkSection>
      <SideBySide>
        <Rect invert>
          <h2>Roles & Responsibilities</h2>
        </Rect>
        <Rect>
          <p>For this project, I performed <em>problem analysis</em>, made <em>wireframes</em>, <em>interactive designs</em>, and the <em>MVP implementation</em>. The CTO helped me cut scope for the MVP and implemented some follow-up changes.</p>
        </Rect>
      </SideBySide>
    </DarkSection>
    <DarkSection>
      <App title="Trouble getting around the codebase">
        <center>
          <iframe src="https://player.vimeo.com/video/435405334" width="640" height="364" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen title='Trouble getting around the codebase'></iframe>
        </center>
        <p>Dark lays its constructs (code and data schemas) in floating boxes. Users spend their time panning, dragging, jumping around their project space instead of coding. <em>Why do they do that?</em></p>
      </App>
    </DarkSection>
    <DarkSection>
      <Rect invert><h4>1. To verify DB schemas</h4></Rect>
      <App title="Database schema">
        <center>
          <p>Often users need to drag databases close to their code because <code>DB::set</code> requires the data inserted to match the exact schema.</p>
          <iframe src="https://player.vimeo.com/video/435405240" width="505" height="300" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen title='DB schema'></iframe></center>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="Database in functions">
        <center><iframe src="https://player.vimeo.com/video/435577183" width="450" height="300" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen title='database in functions'></iframe>
          <br />
          <div><Notes inline>When a function has code referring to a DB, the experience is even worse! </Notes> Since functions are in their own separate space, users must to go back to the main space to see the DB schema.<Notes inline> They must either have fantastic short-term memory or take a screenshot of the DB to double-check their code is using the correct schema.</Notes></div>
        </center>
      </App>
    </DarkSection>
    <DarkSection>
      <Rect invert><h4>2. To read/write in functions</h4></Rect>
      <App title="Our funky function space">
        <center>
          <iframe src="https://player.vimeo.com/video/435578880" width="727" height="300" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen title='Editing functions'></iframe>
          Users can create functions, which will be accessible throughout their project. But these functions are NOT accessible within the main space. <Notes inline>They exist in their own little world. Nor are they accessible from the code that uses them. They can only be reached through the sidebar.</Notes>
        </center>
      </App>
    </DarkSection>
    <DarkSection>
      <Rect invert><h4>3. To check up on workers</h4></Rect>
      <App title="Worker queues">
        <p>Workers are like async functions that appear in the main space. But that doesn't make using them any easier.</p>
        <Image file="worker_diff_inputs.gif" style={{ height: "200px", width: "auto" }} />
        <Notes>They take only one argument <code>event</code> of type <code>Any</code>. The user has to drag a worker near their code to understand its expected input (just like they do with DBs). But unlike DBs, which have a set schema, the user must read through the code to figure out what is the expected type and structure of the input.</Notes>
      </App>
    </DarkSection>
    <DarkSection
      data-background-image={`${process.env.PUBLIC_URL}/darkflow/nyc.jpeg`}
      data-background-position="bottom center -200px"
      data-background-color="#010101"
      data-background-size="cover"
      data-background-opacity="0.3">
      <Rect invert>
        <h2>How did we get here?</h2>
      </Rect>
      <Rect>
        <blockquote>Problems we face today are created by people in the past with the best intentions</blockquote>

        <Notes>The founder's vision for Dark is to bring data into development. He would start his pitch with "imagine a city, where you have boroughs and neighborhoods...". He wanted Dark's constructs to be laid out as landmarks on a map. The value of Dark is it would show users live (or cumulative) traffic as it flows through the codebase.</Notes>

        <p>The floating boxes (of code and DBs) are one day supposed to have dots/lines between them to show live traffic. They will organize code for users, replacing folders and files. File systems, he theorized, are arbitrary ways of splitting code. They give users more problems to worry about (such as dependency cycles) than solutions.</p>
      </Rect>
    </DarkSection>
    <DarkSection>
      <Rect invert><h4>Let's think through the city analogy</h4></Rect>
      <SideBySide>
        <Image file="dresden_map.png" style={{ height: "300px" }} />
        <Image file="habits_map.jpg" style={{ height: "300px" }} />
      </SideBySide>
      <Rect>
        <Notes>Here we have a map of my favorite European city, Dresden (left) and the architecture diagram of simple habits tracker app (right).</Notes>
        <p>If the endpoints and datastores are landmarks, then the path data takes are streets and highways.</p>
      </Rect>
    </DarkSection>
    <DarkSection>
      <SideBySide>
        <Image file="dresden_path.png" style={{ height: "300px" }} />
        <Image file="habits_path.jpg" style={{ height: "300px" }} />
      </SideBySide>
      <Rect>The path of a single trace is a trip.</Rect>
    </DarkSection>
    <DarkSection>
      <SideBySide>
        <Image file="dresden_traffic.png" style={{ height: "300px" }} />
        <Image file="habits_traffic.jpg" style={{ height: "300px" }} />
      </SideBySide>
      <Rect>
        <Notes>Google maps show congestion. The API traffic map shows the request frequency of data passing through endpoints and databases.</Notes>
        <p>Over time we can construct a cumulative flow illustrating the popularity of some routes. The user will know which parts of the codebase require more resources.</p>
      </Rect>
    </DarkSection>
    <DarkSection>
      <Rect special>Unfortunately, most of our users don't have live traffic yet <Notes inline>for the traffic feature to be useful. They find Dark such a frustrating experience they never finish their projects.</Notes></Rect>
      <App title="but we are not there yet...">
        <Image file="overlap.png" />
        <Notes>They struggle to understand what is going on in their codebase because everything is too disorganized.</Notes>
        {/* <small>At the time of this project (early 2019). Now we have many users with live traffic</small> */}
      </App>
    </DarkSection>
    <DarkSection>
      <Rect invert>
        <h2>First, we must pave the streets</h2>
      </Rect>
      <SideBySide>
        <Rect>
          <p>How can we help users understand their codebase? So they may write, debug, and edit code with ease?</p>
          <Notes>We'll use a simple habits tracker application to explain our user's issues.
            You set a weekly goal for how many times you want to do the activity (ie: run 3 times a week). The app calls the API's endpoint <code>POST /track</code> when you perform the habit. The API checks to see if you've reached your goal this week. If you have, you'll get a text with a cute meme. Otherwise, you'll just get a motivational quote instead.</Notes>
        </Rect>
        <Image file="habits.png" style={{ width: "200px", borderRadius: "16px", marginLeft: "20px" }} />
      </SideBySide>
    </DarkSection>
    <DarkSection>
      <Rect invert><h2>City planning!</h2></Rect>
      <Rect>
        <p>Our solution will need to answer the following questions:</p>
        <ul>
          <li>What are all the possible paths a trace can take?</li>
          <li>How do we navigate through these paths?</li>
          <li>What information should be available for each construct type?</li>
        </ul>
      </Rect>
    </DarkSection>
    <DarkSection>
      <App title="Let's consider one path">
        <Image file="path.png" style={{ height: "50px" }} />
        <p><code>POST /track</code> gets hit when you've completed a habit. Most of the execution is offloaded to a queue (the <code>"didHabit" worker</code>). The worker will add an entry to the <code>Tracker DB</code>, and <em>send you a meme</em> if you've met your weekly goal.</p>

      </App>
    </DarkSection>
    <DarkSection>
      <App title="Adding points along the path">
        <Image file="path.png" className="slides-only" style={{ height: "50px" }} />
        <table>
          <tr><td style={{ padding: 0, height: "315px" }}>
            <Image file="start-path-1.png" style={{ maxWidth: "100%", maxHeight: "100%" }} /></td>
            <td style={{ padding: 0, height: "315px" }}>
              <Image file="start-path-2.png" className="fragment" data-fragment-index="1" style={{ maxWidth: "100%", maxHeight: "100%" }} /></td></tr>
        </table>

        We start with <code>POST /track</code>, the  <code>DB::get</code> statement in its code references <code>Habits DB</code>. And emits an event to <code>"didHabit" worker</code>.<Notes>So we add them as references. References allow the user to go through the program's control flow.</Notes>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="Going down the path">
        <Image file="path.png" className="slides-only" style={{ height: "50px" }} />
        <Image file="focus-worker.png" />
        <Notes>When editing <code>didHabit worker</code>, the user will still see most of <code>POST /track</code>.</Notes>
        {/* TODO WTF this makes no sense */}
        <Notes>When the user clicks into a reference, the code inside it will show and viewport pans to focus on it. After in focus, its own references will be aded to the right. In our navigation analogy, you have made your turn and now directions for the next steps are visible. </Notes>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="">
        <blockquote>Are we there yet?</blockquote>

        <p>Have we mapped out all the paths we want to capture? When <code>POST /track</code> gets hit, most of its execution is offloaded to a queue (the <code>"didHabit" worker</code>). The worker will add an entry to the <code>Tracker DB</code>, and <em>send you a meme</em> if you've met your weekly goal.</p>

        <table style={{ width: "100%" }}>
          <tr>
            <td><Image file="post_track.png" /></td>
            <td><Image file="worker_didhabit.png" /></td>
          </tr>
        </table>
        <p>What is it that is missing? <em className="fragment">Send meme or encouragement!</em>. ... and how do we get there?</p>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="references to functions">
        <p>Looks like they are functions. And a problem our users face is:</p>
        <blockquote> Functions are NOT accessible within the main space; they exist in their own little world. Nor are they accessible from the code that uses them.</blockquote>
        <p className="fragment" data-fragment-index="1">Seems like functions references will be useful</p>
        <table>
          <tr><td style={{ padding: 0, height: "315px" }}>
            <Image file="worker_hi_fn-1.jpg" style={{ maxWidth: "100%", maxHeight: "100%" }} /></td>
            <td style={{ padding: 0, height: "315px" }}>
              <Image file="worker_fn_refs.png" className="fragment" data-fragment-index="1" style={{ maxWidth: "100%", maxHeight: "100%" }} /></td></tr>
        </table>
      </App>
    </DarkSection>
    <DarkSection data-transition="fade-out">
      <App title="preview a function's code">
        <p>Now, when the user clicks into a function's reference, they can see the code.</p>
        <Image file="inside_fn.png" />
      </App>
    </DarkSection>
    <DarkSection>
      <Rect invert><h3>How to represent landmarks?</h3></Rect>
      <Rect>
        <Notes>So far references are just boxy links. How can we use the space to provide useful information to the user?</Notes>
        <SideBySide>

          <Image file="reststop_good.jpg" style={{ height: "240px" }} />
          <div>

            <Notes>The rest stop signs on highways provide information on what's coming ahead.</Notes>
            <p>Sign designers anticipate a driver's needs as they drive by. Do they need a bathroom break? More gas? A snack? <em>What are our user's needs?</em></p>
          </div>
        </SideBySide>
      </Rect>
    </DarkSection>
    <DarkSection>
      <App title="Using database schema">
        <p>Users drag DBs closer to their code to check schema.</p>
        <Image file="post-db-refs.png" />
        <Notes>Let's put the schema into a DB reference, so they will no longer have to drag the DB over to validate their code.</Notes>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="Using a function definition">
        <p>Users want to see what happens to their code when it passes through a function.</p>
        <Image file="post-fn-refs.png" />
        <Notes>We can derive the return type from the code and show the function's input and output signature.</Notes>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="Using a worker">
        <blockquote>
          Users want to know what a worker expects for its input (<code>event : Any</code>)
        </blockquote>
        <Image file="post-worker-refs.png" />
        <Notes>We can derive the expected input by analyzing the worker's code!</Notes>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="Designing our city">
        <p>Let's bring some color consistency and typographic hierarchy.</p>
        <table><tr>
          <td style={{ verticalAlign: "middle" }}><Image file="refs_types.png" /></td>
          <td style={{ verticalAlign: "middle" }}><Image file="refs_designed.png" style={{ height: "200px" }} className="fragment" data-fragment-index="1" /></td>
        </tr>
        </table>

        <p className="fragment" data-fragment-index="2">Now how should we arrange our references in relation to the code?</p>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="Considering constraints">
        <Image file="path.png" style={{ height: "50px" }} />
        <p>Going down the path horizontally might not work. <em>Live values are on the left!</em></p>
        <Image file="live_values.png" />
        <Notes>Since they are a product's core value proposition, we can't just relocate them without deeper consideration. And convincing the team to move live values can take months. I decided to save my energy.</Notes>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="Showing direction of flow">
        <p>For now let's draw all the references on the right side with <em>incoming and outgoing arrows</em>. We'll think more about their ideal placement later.</p>
        <Image file="lv_with_references.png" />
      </App>
      <Rect invert><h4>It is more important to bring the value of references to users sooner, so we may validate or falsify their usefulness.</h4></Rect>
    </DarkSection>
    <DarkSection>
      <App title="Putting it all together">
        <Image file="hover.gif" />
        <Notes>Hovering over references will highlight where they are used in the code.</Notes>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="Navigating through your code">
        <Image file="goto.gif" />
        <Notes>Clicking on the reference of a function or worker will take you to the code inside.</Notes>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="Making it real!">
        <Notes>When it came time to build, we had to trim our requirements. And get an MVP out for users to test and play with. So we had to cut scope.</Notes>
        <ul>Scope cuts:
          <li>Breadcrumbs, it will be nice trace your path. We delayed that to be its own feature to design.</li>
          <li>Only show references on the right for HTTP code blocks.</li>
          <li>Don't show a worker's event structure yet (because it involves complex program analysis)</li>
          <li>Don't derive the return types for functions, because functions will have static return types in the future. </li>
          <li>Instead of expanding to see the code in workers and functions, just pan to them spot in the project space.</li>
        </ul>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="After scope cuts, this is our implemented solution">
        <Image file="impl.gif" />
      </App>
    </DarkSection>
    <DarkSection>
      <Rect invert><h2>Field test</h2></Rect>
      <Rect>
        <p>Overall the feedback was positive. New users pointed out it was one of the features they felt made Dark unique. Existing users even discovered an unintended use case. <em>They used it to depreciate functions and migrate DB schemas.</em></p>
        <p>But there were several things they didn't like:</p>
        <ul>
          <li>The (in/out) arrows are too subtle.</li>
          <li>Viewing a function's code in another space is still disruptive to workflow</li>
          <li>They want to be able to see caller's code and reference's code side by side.</li>
        </ul>
      </Rect>
    </DarkSection>
    <DarkSection>
      <App title="Revisions">
        <Image file="colorarrows.png" />
        <p>A simple fix can be to color the arrow and box differently for incoming and outgoing references.</p>
        <Notes>But the real cause of user confusion is that we threw everything to the right. We didn't want incoming references to conflict with live values. Let's look at how we can <em>solve this conflict.</em></Notes>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="Idea 1: Move it">
        <p>Now that references have proven their worth, we might be able to move live values.</p>
        <Image file="lvabove.png" />
        <Notes>We can place the live values into the code, and they would move with the cursor. They will appear in the line above. If the value is more than one line long, affordances can be added to expand and collapse. <em>But values moving around is obstructive to typing. And it covers the line above, which users may need to read for context.</em></Notes>
      </App>
    </DarkSection>
    <DarkSection>
      <App title="Idea 2: Show depending on user intentions">
        <p><small>The cursor is at a different place when users look at references than when they look at live values.</small></p>
        <Image file="just-refs.png" data-fragment-index="1" className="fragment" style={{ height: "160px" }} />
        <p data-fragment-index="1" className="fragment"><small>A user looks at incoming references to see its usage. The code (and consequently live values) can be hidden. <em>We don't need to show live values in that case.</em></small></p>

        <Image file="fade-ref.png" data-fragment-index="2" className="fragment" style={{ height: "160px" }} />
        <p data-fragment-index="2" className="fragment"><small>When a user edits code, they want to know what is happening to the data. <em>We fade out the incoming references and show the live value then.</em></small></p>
      </App>
    </DarkSection>
    <DarkSection
      data-background-image={`${process.env.PUBLIC_URL}/darkflow/nyc.jpeg`}
      data-background-position="bottom center -200px"
      data-background-color="#010101"
      data-background-size="cover"
      data-background-opacity="0.3">
      <Rect invert><h2>Retrospective</h2></Rect>
      <Rect>
        <p>After the scope was cut, and we implemented references for workers and functions. But there was no plan to implement other cut features nor execute on revisions.</p>
        <p>Without moving our incoming references on the opposite side of outgoing references, the map/navigation analogy is broken.</p>
      </Rect>
    </DarkSection>
  </>)
};
export default content;