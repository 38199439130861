import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

import SlideShow from "../bits/Slideshow";

import { sizes, Dot, SideBySide } from '../bits/sharedStyles';
import LynxIf from "../content/lynxIf";
import { DarkSection, Rect, styles as DarkStyles } from '../bits/darkSlides';
import DarkTooltips from '../content/darkTips';

import '../bits/show.css'

export const styles = StyleSheet.create({
  picture: {
    border: '1px solid',
    ':nth-child(1n)': {
      borderColor: 'var(--lynx-purple0)',
      boxShadow: `${sizes.pad / 2}px ${sizes.pad / 2}px 0 var(--lynx-purple0)`
    },
    ':nth-child(2n)': {
      borderColor: 'var(--lynx-yellow)',
      boxShadow: `${sizes.pad / 2}px ${sizes.pad / 2}px 0 var(--lynx-yellow)`
    },
    ':nth-child(3n)': {
      borderColor: 'var(--lynx-green0)',
      boxShadow: `${sizes.pad / 2}px ${sizes.pad / 2}px 0 var(--lynx-green0)`
    },
  },
  bgPurple: {
    backgroundColor: 'var(--lynx-purple0)'
  }
});

const Image = ({ file, alt, ...props }) => (<img
  className={css(styles.picture)}
  src={`${process.env.PUBLIC_URL}/me/${file}`}
  alt={alt}
  {...props} />);

const page = () => (<SlideShow>
  <DarkSection>
    <Rect special invert>
      <h1>Alice Q. Wong</h1>
      <i>Interaction Designer. User Experience Engineer.</i>
    </Rect>
  </DarkSection>
  {/* <section>
    Career
  </section> */}
  <DarkSection>
    <div style={{ position: 'relative' }}>
      <Image file="lampshade.png" style={{ width: '300px', transform: 'rotate(2deg) translate(0, -100px)' }} />
      <Image file="cathouse.jpg" style={{ width: '300px', transform: 'rotate(-2deg)' }} />
      <Image file="roth_2.jpg" style={{ width: '300px', transform: 'rotate(2deg) translate(10px, -110px)' }} />
      <Image file="roth_1.jpg" style={{ width: '260px', transform: 'rotate(2deg)' }} />
      <Image file="piggybank.jpg" style={{ width: '200px', transform: 'rotate(1deg)' }} />
      <Image file="cardboard_1.jpg" style={{ width: '200px', transform: 'rotate(4deg)' }} />
    </div>
  </DarkSection>
  <DarkSection>
    <div>
    </div>
    <SideBySide style={{ alignItems: "flex-start" }}>
      <Rect invert ><h2>Agenda</h2></Rect>
      <Rect>
        <p><Dot color={DarkStyles.bgBlue}></Dot>&nbsp; Demo some features I made @ Dark</p>
        <p><Dot color={DarkStyles.bgPurple}></Dot>&nbsp; Onboarding with tooltips @ Dark</p>
        <p><Dot color={DarkStyles.bgCyan}></Dot>&nbsp; Representing conditionals @ Lynxtool</p>
        <p><Dot color={DarkStyles.bgYellow}></Dot>&nbsp; Figma walk through Lynxtool</p>
      </Rect>
    </SideBySide>
  </DarkSection>
  <DarkTooltips isSlideShow={true} />
  <LynxIf isSlideShow={true} />
</SlideShow>);

export default page;