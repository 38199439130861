import * as React from "react"
import { StyleSheet, css } from 'aphrodite/no-important';

import Layout from "../bits/Layout";
import { SideBySide, Rect, sizes, Sticky } from "../bits/sharedStyles";

const styles = StyleSheet.create({
  picture: {
    border: '2px solid',
    ':nth-child(even)': {
        borderColor: 'var(--color-primary)',
        boxShadow: `${sizes.pad/2}px ${sizes.pad/2}px 0 var(--color-primary)`
    },
    ':nth-child(odd)': {
        borderColor: 'var(--color-accent)',
        boxShadow: `${sizes.pad/2}px ${sizes.pad/2}px 0 var(--color-accent)`
    },
  },
  gallery: {
    textAlign: "center",
    paddingTop: "100px"
  }
});

const Image = ({file, alt, ...props}) => (<img
  className={css(styles.picture)}
  src={`${process.env.PUBLIC_URL}/me/${file}`}
  alt={alt}
  {...props} />);

const page = () => {
  return (<Layout>
    <SideBySide style={{alignItems: "flex-start"}}>
    <Rect invert style={{minWidth: "300px"}}>
    <h1>About Alice</h1>
        <p>Product Designer, UX Engineer, and cardboard carpenter.<br />
        A proud Jack of All Trades, and Master of Metaphors</p>
      </Rect>
    <Rect>

        <p>In the past, the industrial revolution defined our values. The assembly line praised specialization. "Jack of all trades; master of none" was one saying that resonated in the past century. As a jack, I can tell you that we are all masters of one skill: learning.</p>
      <p>Connections and patterns are my main tools for problem-solving. I translate data (from user interviews, competitive analysis, etc.) into thematic discoveries (user pain points). I delight in converting conceptual problems into tangible solutions (prototypes and products).</p>

        <p>To learn more about my career, <a href="/resume">check out my CV.</a></p>
    </Rect>
    </SideBySide>
    <div style={{position: "relative"}}>
        <Sticky>When I am not solving problems, I like to: paint, cook, and make things with re-purposed materials.</Sticky>
        <div className={css(styles.gallery)}>
          <Image file="cardboard_1.jpg" style={{width:'150px', transform: 'rotate(-4deg)'}} />
          <Image file="piggybank.jpg" style={{width:'120px', transform: 'rotate(1deg)'}} />
          <Image file="roth_1.jpg" style={{width:'150px', transform: 'rotate(2deg)'}} />
          <Image file="roth_2.jpg" style={{width:'200px', transform: 'rotate(2-3deg)'}} />
          <Image file="lampshade.png" style={{width: '300px', transform: 'rotate(4deg)'}} />
          <Image file="paint.jpg" style={{width:'200px', transform:'rotate(-2deg)'}} />
          <Image file="salad.jpg" style={{width:'120px', transform:'rotate(2deg)'}} />
          <Image file="drinks.jpg" style={{width:'120px', transform:'rotate(-5deg) translate(0, -50px)'}} />
        </div>
    </div>
  </Layout>);
}

export default page;
