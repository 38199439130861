import Layout from "../bits/Layout";
import { StyleSheet, css } from 'aphrodite/no-important';
import { Link } from "react-router-dom";

import { sizes, Rect, App, buttonStyle } from '../bits/sharedStyles';
import Projects from '../content/projects';

const styles = StyleSheet.create({
  profileImage: {
    width: '100%',
    "@media (min-width: 768px)": {
      width: '250px'
    },
  },

  splashToken: {
    display: 'inline-block',
    marginRight: `${sizes.pad}px`,
    lineHeight: '2.0em'
  },

  splashMessage: {
    fontSize: '1.0em',
    textAlign: 'center',

    "@media (min-width: 768px)": {
      textAlign: 'center'
    }
  },

  splashFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: `${sizes.sectionSpace}px`,
    marginBottom: `${2 * sizes.sectionSpace}px`,

    "@media (min-width: 768px)": {
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "flex-start",
      flexWrap: "nowrap"
    },
  },

  more: {
    width: "100%",
    textAlign: "center",
    margin: `${2 * sizes.sectionSpace}px 0`
  }

})

const Token = ({ children }) => (<span className={css(styles.splashToken)}>{children}</span>)

function page() {

  const inlineRectStyle = (color) => ({
    display: 'inline-block',
    marginRight: `${sizes.pad}px`,
    marginBottom: `${sizes.pad}px`,
    padding: `${sizes.pad / 2}px`,
    // borderColor: 'var(--color-dark-grey)',
    boxShadow: `${sizes.pad / 4}px ${sizes.pad / 4}px 0 ${color},
        ${sizes.pad / 4}px ${sizes.pad / 4}px 0 1px var(--color-dark-grey)`
  });

  return (
    <Layout>
      <div className={css(styles.splashFlex)}>
        <App title="Hey there, I'm Alice" fullBleed>
          {/* <Canvas width={256} height={200} /> */}
          <img src={`${process.env.PUBLIC_URL}/me/profile.jpg`} className={css(styles.profileImage)} alt="Alice @ Greenpoint" />
        </App>
        <div className={css(styles.splashMessage)}>
          <Rect style={inlineRectStyle('var(--color-accent)')}>UX Engineer</Rect>
          <Token>&</Token>
          <Rect special style={inlineRectStyle('var(--color-primary)')}>Product Designer</Rect>
          <Token>I want to empower everyone by </Token>
          <Token>making computation accessible.</Token>
          <br /><br />
          <Link to="/resume" className={buttonStyle} style={{ textAlign: "center" }}>See resume</Link>
        </div>
      </div>
      <Projects />
      <div className={css(styles.more)}>
        <Link to="/projects"><h3>see more projects...</h3></Link>
      </div>
    </Layout>
  );
}

export default page;
