import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

import Notes from "../bits/Notes";

import { SideBySide, Dot, sizes } from '../bits/sharedStyles';
import { App, Rect, Hl, styles as DarkStyles, DarkSection } from '../bits/darkSlides';

const vAlignFlex = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
}

const vMargin = {
    marginBottom: `${sizes.sectionSpace}px`
}

const styles = StyleSheet.create({
    topAlignCells: {
        ":nth-child(1n) td": {
            verticalAlign: "top"
        }
    },
    middleAlignCells: {
        ":nth-child(1n) td": {
            verticalAlign: "middle"
        }
    },
    imageMax50: {
        ":nth-child(1n) img": {
            maxHeight: "50px"
        }
    }
});

const Image = ({ file, alt, ...props }) => (<img
    src={`${process.env.PUBLIC_URL}/darktips/${file}`}
    alt={alt} {...props} />);

const content = ({ isSlideShow }) => (<>
    <DarkSection>
        <Rect special invert><h1>Onboarding with tooltips</h1></Rect>
    </DarkSection>
    <DarkSection>
        <App title="What's Dark? Language, infrastructure, code editor" isShow={isSlideShow}>
            <Notes>As the Designer & Front-end Lead, I was responsible for the editor experience. The company's goal is to make API development easier for non-backend developers.</Notes>
            <Image file="ide.png" />
            <Notes>Mobile and web frontend developers benefit from seeing the effects of their code right away by looking at the UI. But you can't do that with backend development. Without tests, backend development is just coding in the dark. Our competitive advantage is we compute everything for you as you code. Our users can see what happens to their data along the way and the results produced by their code. Therefore it should be easy for other engineers to write backends using Dark, right? But it was not!</Notes>
        </App>
    </DarkSection>
    <DarkSection>
        <Rect invert special><h2>Our onboarding problem</h2></Rect>
        <Rect special>
            <p>First-time users didn't know how to get started. Novice users got lost.</p>
            <Notes>They cannot learn Dark without the aid of an external source (i.e., a reference or walkthrough videos). We found the only wait we got users to stick around and build something with Dark was if we onboarded them manually through a video call. We didn't have a dedicated customer support person and took on support responsibilities ourselves for the longest time.</Notes>

            <SideBySide style={{ alignItems: 'flex-start' }}>
                <div style={{ textAlign: "center" }}>
                    <Image file="peeps.png" alt="team" height={isSlideShow ? "250" : "auto"} />
                    <Notes><span style={{ fontSize: "0.7em" }}>% of team onboarding</span></Notes>
                </div>
                <Notes>
                    As the amount of interested beta users on our waitlist grew, we tried to keep a warm pipeline by bringing in other teammates. But with each onboarding session taking 45-60mins, bringing on new users became very expensive. I floated around the idea of in-editor onboarding, like the tutorial portion of a game, where you learn the mechanics during the first few levels as you play. As our growth was hindered by the expensive top-of-funnel block, we were finally able to prioritize building an in-editor onboarding tool.</Notes>
            </SideBySide>

            <h4><Hl>Why didn't we address it sooner?</Hl></h4>
            <Notes>Our initial market positioning gave us a skewed sense of who our users will be. This knowledge rippled into how we constructed our roadmap (and how we prioritized features). Dark initially positioned itself as a new language; it attracted attention from the programming languages community.</Notes>
        </Rect>
    </DarkSection>
    <DarkSection>
        <SideBySide>
            <div style={{ width: "50%" }}>
                <Rect invert special><h3>The Navigator</h3></Rect>
                <Rect special>
                    <Image file="pl.png" width="100" />
                    <div className="slides-only">
                        <p><Dot color={DarkStyles.bgPurple}></Dot>&nbsp; academic</p>
                        <p><Dot color={DarkStyles.bgBlue}></Dot>&nbsp; patient</p>
                        <p><Dot color={DarkStyles.bgCyan}></Dot>&nbsp; learn before doing</p>
                        <p><Dot color={DarkStyles.bgYellow}></Dot>&nbsp; <Hl>BUT &lt; 10% shipped projects</Hl></p>
                    </div>
                    <Notes inline>The programming languages community was a very academic crowd.They approached new concepts with patience, willing to learn first before doing.&nbsp;
                        <Hl>But they rarely ship projects in Dark.</Hl>
                        Their interest in Dark was purely theoretical. It seems that we weren't providing value to this user group. Less than 10% of our users completing their projects is not a great statistic to be telling our investors. </Notes>
                </Rect>
            </div>
            <div style={{ width: "50%" }}>
                <Rect invert><h3>The Explorer</h3></Rect>
                <Rect>
                    <Image file="fe.png" width="100" />
                    <div className="slides-only">
                        <p><Dot color={DarkStyles.bgYellow}></Dot>&nbsp; new devs, students, FE</p>
                        <p><Dot color={DarkStyles.bgCyan}></Dot>&nbsp; jump right in</p>
                        <p><Dot color={DarkStyles.bgBlue}></Dot>&nbsp; learn by doing</p>
                        <p><Dot color={DarkStyles.bgPurple}></Dot>&nbsp; &gt; 40% made projects in Dark</p>
                    </div>
                    <Notes inline>The most successful demographic, who ship projects in Dark, where those who had a product they wanted to build, but don't want to bother with learning the complexities of building a backend. They turn out to be new developers, students, and front-end engineers. They explore new territory <Hl>and learn by doing!</Hl> To these people, Dark enabled them to make something in half the time using anything else.</Notes>
                </Rect>
            </div>
        </SideBySide>
    </DarkSection >

    <DarkSection data-transition="slide-in slide-up">
        <Rect invert>
            <h3>How can we tailor learning Dark for the explorer?</h3>
            <Notes>This project was part of a larger initiative to pivot our target user groups.</Notes>
        </Rect>
        <div className="fragment fade-up" style={vMargin}>
            <Rect>Project tutorials<Notes inline>. Most Javascript frameworks start with a todo list tutorial because many frontend engineers are explorers and learn by example.</Notes></Rect></div>
        <div className="fragment fade-up" style={vMargin}><Rect>Code sharing<Notes inline> empowers users to learn from one another and build upon eachother's knowledge. It also helps us foster our community.</Notes></Rect></div>
        <div className="fragment fade-up" style={vMargin}><Rect special>Tooltips<Notes inline> have been aiding people to learn software through exploration. They provide additional information to what non-obvious icons mean and suggest actions users can perform. Since we already had tooltip, we wanted to leverage them to help us onboard users. But fix we must to a little clean up. </Notes></Rect></div>
        {/* <div className="page-only"><Rect invert special><p style={{ textAlign: "center", fontStyle: "italic", fontSize: "1.2em", fontWeight: "bold" }}>Let's focus just tooltips for now.</p></Rect></div> */}
    </DarkSection>

    <DarkSection className="dark">
        <App title="Current tooltips are inconsistent" isShow={isSlideShow}>
            <Notes>Our editor already had tooltips, but they are inconsistent in both style and behavior.</Notes>
            <table><tr>
                <td><Image file="bad1.png" /></td>
                <td><Image file="bad2.gif" /></td>
            </tr>
                <tr>
                    <td><Image file="bad4.gif" /></td>
                    <td><Image file="bad3.png" /></td>
                </tr>
            </table>
        </App>
    </DarkSection>
    <DarkSection>
        <Rect invert style={{ vAlignFlex, minWidth: "200px" }}><h2>My role</h2></Rect>
        <Rect>
            <Image file="dark_roles_at_tips.png" />

            <p className="slides-only">Collab with CTO (stakeholder) on design; architect solution and mentor FE in implementation.</p>

            <Notes>First, I collaborated with our CTO (stakeholder) to <Hl>design</Hl> an extensible tooltip component. Then, I <Hl>mentored</Hl> our frontend engineer on building the new component, and <Hl>architect</Hl> a solution to use them to onboard users.</Notes>
        </Rect>
    </DarkSection>
    <DarkSection>
        <Rect invert><h3>Understanding the problem</h3></Rect>
        <Rect special>
            <p>Our two sub-problems:</p>
            <p><Dot color={DarkStyles.bgPurple} />&nbsp;Fix visual design issue<Notes inline>, because there's no meaningful distinction between different styles</Notes></p>
            <p><Dot color={DarkStyles.bgYellow} />&nbsp;Use to on-board <Notes inline>new users. How can we leverage out tooltips to help them learn along the way. We can use them not only as labels, but also as an in-editor documentation.</Notes></p>
        </Rect>
        <Rect>
            But consistency can't be achieved with just rigor to a style alone. <Notes inline>This mess did not happen because every contributor had a different aesthetic taste. We got here because we aimed only to solve the current problem every time we added a new tooltip.</Notes> <Hl> We were short-sighted before.</Hl>
        </Rect>
        <div className="fragment"><Rect special><Notes inline>Let's take a step back to</Notes> understand what we were trying to achieve in the <Hl>past</Hl>, and what we want to accomplish in the <Hl>future</Hl>. <Notes inline>What features will our tooltip component need to aid in-editor onboarding?</Notes></Rect></div>
    </DarkSection>
    {/* <DarkSection id="ideate" className="before-change">
        <SideBySide>
            <Rect invert special style={vAlignFlex}><h2>Approaching the problem</h2></Rect>
            <Rect special>
                <p>Identify <Notes inline>First, found all instances where tooltips are used in the editor.</Notes></p>
                <p>Derive meaning <Notes inline>by categorizing them by the functions they serve.</Notes></p>
                <p>Design <Notes inline>to convey purpose with visual distinctions.</Notes></p>
            </Rect>
        </SideBySide>
    </DarkSection> */}
    <DarkSection>
        <App title="Taxonomize the chaos" isShow={isSlideShow}>
            <table className={css(styles.middleAlignCells, styles.imageMax50)}>
                <tr><td colSpan="2"><p>Our editor has over 60 tooltips. They all fall into one of the four categories:</p></td></tr>
                <tr>
                    <td><small><Hl>Labels</Hl> provide text equivalents (in 5 words or less) to icons</small></td>
                    <td align="center"><Image file="cat-label.png" /></td>
                </tr>
                <tr><td><small><Hl>Descriptions</Hl> that explain why something happens or how it works.
                </small></td>
                    <td align="center"><Image file="cat-detail.png" /></td>
                </tr>
                <tr><td><small><Hl>Exceptions</Hl> explain deviations from expected behavior.</small></td>
                    <td align="center"><Image file="cat-except.png" /></td>
                </tr>
                <tr><td><small><Hl>Suggested actions</Hl> tells a user to do something to help them along</small></td>
                    <td align="center"><Image file="bad3.png" /></td>
                </tr>
            </table>
        </App>
    </DarkSection>
    <DarkSection>
        <App title="The component model" isShow={isSlideShow}>
            <Notes>A tooltip component will have...</Notes>
            <table className="small-on-slides">
                <thead style={{
                    borderBottom: `1px solid grey`
                }}><tr><th>Property</th><th>Data type</th><th>Required?</th></tr></thead>
                <tbody>
                    <tr><td>Attached element</td><td>DOM element</td>
                        <td className={css(DarkStyles.cyanText)}>✔︎</td></tr>
                    <tr><td>Title</td><td>Text</td>
                        <td className={css(DarkStyles.cyanText)}>✔︎</td></tr>
                    <tr><td>Description</td><td>Text</td>
                        <td className={css(DarkStyles.purpleText)}>✘</td></tr>
                    <tr><td>Button label</td><td>Text</td>
                        <td className={css(DarkStyles.purpleText)}>✘</td></tr>
                    <tr><td>Button action</td><td>Function</td>
                        <td className={css(DarkStyles.purpleText)}>✘</td></tr>
                    <tr><td>Style</td><td><Hl>INFO</Hl> or <Hl>EXCEPTION</Hl></td><td className={css(DarkStyles.cyanText)}>✔︎</td></tr>
                </tbody>
            </table>
            <Notes>Now we've got the product requirements down, let's make it pretty!</Notes>
        </App>
    </DarkSection>
    <DarkSection>
        <Rect special invert><h4>From our model we will need mocks for:</h4></Rect>
        <Rect special>
            <p><Dot color={DarkStyles.bgBlue} />&nbsp;Tooltip with just a title</p>
            <p><Dot color={DarkStyles.bgYellow} />&nbsp;Tooltip with title and description</p>
            <p><Dot color={DarkStyles.bgCyan} />&nbsp;Tooltip with action button</p>
        </Rect>
        {/* TODO show sketches instead */}
    </DarkSection>
    <DarkSection data-transition="slide-in fade-out">
        <App title="Decide on a style" isShow={isSlideShow}>
            <p>First, I made 6 different treatments, for the simple case.</p>
            <table>
                <tr><td><Image file="design1.png" /></td>
                    <td><Image file="design2.png" /></td>
                    <td><Image file="design3.png" /></td></tr>
                <tr><td><Image file="design4.png" /></td>
                    <td><Image file="design5.png" /></td>
                    <td><Image file="design6.png" /></td></tr>
            </table>
        </App>
    </DarkSection>
    <DarkSection data-transition="fade-in slide-out" className="slides-only">
        <App title="Decide on a style" isShow={isSlideShow}>
            <p>CTO and I narrowed down to 3</p>
            <table>
                <tr><td><Image file="design1.png" /></td><td><Image file="design2.png" /></td><td>&nbsp;</td></tr>
                <tr><td>&nbsp;</td><td>&nbsp;</td><td><Image file="design6.png" /></td></tr>
            </table>
        </App>
    </DarkSection>
    <DarkSection className="dark">
        <App title="with description and action button" isShow={isSlideShow}>
            <Notes>I hashed out the details for our top contenders. And made mocks for tooltips with a description and an action button.</Notes>
            <table>
                <tr><td style={{ borderRight: "1px solid #999" }}><Image file="d2-details.png" /></td>
                    <td style={{ borderRight: "1px solid #999" }}><Image file="d3-details.png" /></td>
                    <td><Image file="d1-details.png" /></td></tr>

                <tr><td style={{ borderRight: "1px solid #999" }}><Image file="d2-action.png" /></td>
                    <td style={{ borderRight: "1px solid #999" }}><Image file="d3-action.png" /></td>
                    <td><Image file="d1-action.png" /></td></tr>
            </table>
        </App>
    </DarkSection>
    <DarkSection>
        <App title="Giving our users a voice" isShow={isSlideShow}>
            <Notes>We had a little over 200 users, and we had a close relationship with them. I performed customer support for the company, so I could get their engagement when I needed to send our surveys or conduct interviews. Since our users are giving us their time and effort to learn the product, we also want to give our users some agency over the future of Dark. After our team makes internal strategic decisions, we sometimes reach out to our community and ask their input.</Notes>
            <p>We asked our users to vote on the top 3 styles, and this won out.</p>
            <table className={css(styles.topAlignCells)}>
                <tr><td><Image file="design6.png" /></td><td><Image file="d1-details.png" /></td><td><Image file="d1-action.png" /></td></tr>
            </table>
        </App>
    </DarkSection>
    <DarkSection>
        <App title="Capturing the error case" isShow={isSlideShow}>
            <p>I also want to distinguish informational from exception tooltips. Translating the new <Hl>style</Hl> property into UI</p>
            <table className="middle-align-cells">
                <tr><td>INFO</td>
                    <td><Image file="simple.png" /></td>
                    <td><Image file="details.png" /></td>
                    <td><Image file="actions.png" /></td></tr>
                <tr><td>EXCEPTION</td>
                    <td><Image file="err-simple.png" /></td>
                    <td><Image file="err-details.png" /></td>
                    <td><Image file="err-action.png" /></td></tr>
            </table>
            <Notes>I deliberately chose to have the red title text on a light background instead of just applying a red background. It will be easier for red-green color-blind users to distinguish between informational and exception tooltips.</Notes>
        </App>
    </DarkSection>

    <DarkSection>
        <Rect invert style={vAlignFlex}><h3>Future features</h3></Rect>
        <div>
            <div className="fragment" style={vMargin}><Rect
            >Right now, tooltips have no memory.<Notes>They will show regardless if the user had seen them before.
                Especially since we want to guide new users through the editor when they first start. This will require some tooltips to popup on their own. But this can be obstructive to a seasoned users, who know what they are doing.</Notes>
            </Rect></div>
            <div className="fragment" style={vMargin}><Rect special>How do we make them not annoying? <Notes>If only we can determine the conditions they will appear, then we can use them to help new users.</Notes></Rect></div>
            <div className="fragment"><Rect>Tooltips will also need a <Hl>trigger condition</Hl>.
                <p className="page-only">To keep track of who to show it to, and when to show them.</p>
            </Rect></div>
        </div>
    </DarkSection>
    <DarkSection>
        <Rect invert><h2>Revised model</h2></Rect>
        <Rect>
            <table className='small-on-slides'>
                <thead style={{
                    borderBottom: `1px solid grey`
                }}>
                    <tr><th>Property</th><th>Data type</th><th>Required?</th></tr>
                </thead>
                <tr><td>Attached element</td><td>DOM element</td><td className={css(DarkStyles.cyanText)}>✔︎</td></tr>
                <tr><td>Title</td><td>Text</td><td className={css(DarkStyles.cyanText)}>✔︎</td></tr>
                <tr><td>Description</td><td>Text</td><td className={css(DarkStyles.purpleText)}>✘</td></tr>
                <tr><td>Button label</td><td>Text</td><td className={css(DarkStyles.purpleText)}>✘</td></tr>
                <tr><td>Button action</td><td>Function</td><td className={css(DarkStyles.purpleText)}>✘</td></tr>
                <tr><td>Style</td><td><Hl>INFO</Hl> or <Hl>EXCEPTION</Hl></td><td className={css(DarkStyles.cyanText)}>✔︎</td></tr>
                <tr><td>Trigger condition</td><td>Function</td><td className={css(DarkStyles.purpleText)}>✘</td></tr>
            </table></Rect>
    </DarkSection>
    <DarkSection>
        <App title="Implementation Kickoff" isShow={isSlideShow}>
            <Notes>Luckily, after re-positioning our product with an increased focus on in-editor learning.</Notes>
            <SideBySide>
                <div style={{ paddingTop: "30px" }}>A PM used this as a jumping-off point to create an in-editor onboarding flow. We decided to track how new users decided to go with the tutorial and how many dismissed it. And the churn rate and project completion rate for each of these groups.
                    <Notes>Unfortunately, it was the last project our FE did before we all got laid off during the pandemic, so we didn't get the chance to follow up on these results.</Notes></div>
                <Image file="impl.png" style={{ height: "500px" }} />
            </SideBySide>
        </App>
    </DarkSection>

    {/* <DarkSection className="before-change">
        <Rect invert><h2>Future tooltips</h2></Rect>
        <Rect>
            <Notes>The first part of this project was to make existing tooltips consistent. I also want to make it possible to onboard new users with them.</Notes>
            <p>From watching many first time users struggle with our product, I wrote a proposal for all the places we should have tooltips to help new users learn.</p>
        </Rect>
    </DarkSection>
    <DarkSection className="dark">
        <App title="Context-aware tooltips" isShow={isSlideShow}>
            <p><small>For example, <Hl>Dark captures all incoming data, even invalid requests. Users can use that data to construct their program.</Hl> We want to make sure new users knew about this feature.</small></p>
            <Notes>The image shows the element that the tooltip should be attached with, and also the style property (info or exception). The rest of the model is dictated in writing. For all design that requires copy written, I write the copy in the specification document. This not only makes it easy for developers to copy and paste but also for product people to edit it.</Notes>
            <table className={css(styles.topAlignCells) + " small-on-slides"}>
                <tr><td rowSpan="3"><Image file="future-404.png" /></td>
                    <td><b>Title</b></td>
                    <td>Handle your 404 errors</td></tr>
                <tr><td><b>Description</b></td>
                    <td>Dark keeps tracks of all incoming requests. You can create an HTTP handler populated with the data from a 404 request.</td></tr>
                <tr><td><b>Trigger</b></td>
                    <td>This tooltip will appear if the project has 404s, and the user has not yet created a handler from their 404s yet. It will appear without them hovering over the 404 icon.</td></tr>
            </table>
        </App>
    </DarkSection> */}

    {/* Build */}
    <DarkSection>
        <Rect invert><h2>Post-design support</h2></Rect>
        <Rect>
            <p><Dot color={DarkStyles.bgBlue} />&nbsp;Architected Implementation <Notes inline>to figure out how the triggered condition will operate in our frontend framework.</Notes></p>
            <p><Dot color={DarkStyles.bgYellow} />&nbsp;Lead pair-programming <Notes inline>with junior FE developer on backend code</Notes></p>
            <p><Dot color={DarkStyles.bgCyan} />&nbsp;Defined metrics <Notes inline>to track on this new feature with PM</Notes></p>
        </Rect>
    </DarkSection>
    <DarkSection>
        <Rect special invert><h2>Styling aid</h2></Rect>
        <Rect special>
            <p className="codepen" data-height="400" data-theme-id="dark" data-default-tab="result" data-user="alice" data-slug-hash="QWbmbEj" style={{
                height: "400px",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid",
                margin: "1em 0",
                padding: "1em"
            }} data-pen-title="Tooltips">
                <span>See the Pen <a href="https://codepen.io/alice/pen/QWbmbEj">
                    Tooltips</a> by Alice Q (<a href="https://codepen.io/alice">@alice</a>)
                    on <a href="https://codepen.io">CodePen</a>.</span>
            </p>
            <script async src="https://static.codepen.io/assets/embed/ei.js"></script>
            <Notes>Since we are a small team still in limited beta, we must optimize for development speed. We want to get features in the hands of our users as fast as possible so we may solicit actual usage feedback. We cannot afford design & engineering to go through multiple iteration cycles just on pixel pushing. Since I am the most seasoned frontend developer on the team, I often derisk that by taking responsibility for UI that is quirkier to implement. I include the code for that in the feature specification document or pre-merge necessary components before they start implementation. No need for our engineers to waste time pushing pixels.</Notes>
        </Rect>
    </DarkSection>
    <DarkSection>
        <Rect invert special><h2>Lessons Learned</h2></Rect>
        <Rect special>
            <p>I should have presented the deliverables of this project, highlighting the UX enhancements first instead of styling cleanup. It would have likely gotten more buy-in to design and implement earlier.</p>
        </Rect>
    </DarkSection>
</>);

export default content;