import * as React from "react"
import { StyleSheet, css } from 'aphrodite/no-important';

import {sizes} from './sharedStyles';
import "./icons.css"


const ContactCard = ({labelColor, valueColor}) => {
    const styles = StyleSheet.create({
        value : {
            fontFamily: 'var(font-body)',
            margin: `0 ${sizes.pad}px`,
            color: valueColor
        },
        label: {
            color: labelColor
        },
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center"
        }
    });

    const LabelValue = ({icon, value}) => (<div className={css(styles.row)}>
        <span className={css(styles.label)+' '+icon} />
        <span className={css(styles.value)}>{value}</span>
    </div>);

return (<div>
    <LabelValue icon="icon_mail_alt" value="alice@valour.me" />
    <LabelValue icon="icon_phone" value="+1-(909)-319-4109" />
    <LabelValue icon="icon_globe-2" value="https://aliceqing.com" />
</div>)
};

export default ContactCard;