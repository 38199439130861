import { useState } from "react";

import Content from "../content/lynxIf";
import Layout from "../bits/Layout";
import AccessForm, { storeKey } from '../bits/auth';

import '../bits/case.css'

const Page = () => {
    const [access, setAccess] = useState(sessionStorage.getItem(storeKey) ?? false);

    const updateAccess = (allow) => {
        sessionStorage.setItem(storeKey, allow);
        setAccess(allow);
    }

    return (<Layout>{
        access ? <div style={{ backgroundColor: "var(--color-light)" }}><Content /></div> : <AccessForm reply={updateAccess} />
    }</Layout>);

}


export default Page;