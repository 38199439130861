import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

import Notes from "../bits/Notes";

import { App, Rect, DarkSection } from '../bits/darkSlides';

const s = StyleSheet.create({
	lightImg: {
		boxShadow: "#e5e5ea 0 1px 6px 2px"
	}
})

const Image = ({ file, alt, center, ...props }) => {

	const image = (<img
		src={`${process.env.PUBLIC_URL}/darkteams/${file}`}
		alt={alt} {...props} />);

	if (center) {
		return (<center>{image}</center>);
	}

	return image;
};

const content = ({ isSlideShow }) => (<>
	<DarkSection>
		<Rect special invert><h1>Engineering collaboration</h1></Rect>
	</DarkSection>
	<DarkSection light>
		<Rect invert special><h2>The Problem</h2></Rect>
		<Rect special>
			<Notes>
				Dark was first built for one user. But developers normally work in teams, and we struggled to get larger
				projects
				onboard because of our lack of support for a team's workflow.
			</Notes>
			<blockquote>Multiple users will struggle to work on the same project because they don't know <span
				className="font-blue">who</span> made <span className="font-pink">what</span> changes where and <span
					className="font-green">why</span>.</blockquote>
			<Notes>For a New Year's hack day, I explored ideas around the theme of collaboration. What features
				can help users communicate and work together on the same codebase.</Notes>
		</Rect>
	</DarkSection>
	<DarkSection>
		<Rect invert><h3>Let's breakdown it down</h3></Rect>
		<Rect>
			<ol>For teamwork to be possible in Dark, users need to be able to:
				<li className="fragment">Communicate about their code in the editor.</li>
				<li className="fragment">Learn about the codebase's history.<Notes inline> They should be able to
					find out who to ask for help.</Notes>
				</li>
				<li className="fragment">Make changes to their codebase safely. <Notes inline>
					They need to package their changes for another person to review or deploy.</Notes></li>
			</ol>
		</Rect>
	</DarkSection>
	<DarkSection>
		<Rect invert><h3>Out of scope</h3></Rect>
		<Rect>
			<Notes>Why we solve to solve those three problems and not others?</Notes>
			<ul>Other concerns I thought of but tabled were:
				<li className="fragment">Merge conflicts<Notes inline> - there might be some usability issues we
					need to consider later. With how users will deal with merge conflicts within Dark. But the main bottleneck
					holding us back from was a backend engineering concern.</Notes>
				</li>
				<li className="fragment">Pair-programming<Notes inline> - maybe something down the line. Right now
					users will have to do it through a third party voice/video service. People have actually paired in Dark
					with
					eachother on a Twitch live stream.</Notes>
				</li>
				<li className="fragment">Forking and sharing code<Notes inline> - to be addressed with
					our package manager.</Notes>
				</li>
			</ul>
		</Rect>
	</DarkSection>

	<DarkSection>
		<Rect invert special><h2>Solving: How do I talk about my code?</h2></Rect>
		<Rect>
			<q>Users need to be able to communicate about their code in the editor.</q>
		</Rect>
	</DarkSection>
	<DarkSection light>
		<Rect invert><h3>Constraints</h3></Rect>
		<App title='Only way to "comment" in Dark' isShow={isSlideShow}>
			<Image height="250" center={!isSlideShow}
				file="teamwork-2-a.png"
				alt="code block in Dark" />
			<Notes>Right now the only way for users to add comments in their code is with unassigned strings. Comments are done by just having string literals, there is no clear visual distinction
				between them and other strings in the code.</Notes>
		</App>
		<Rect special><center>It is a workaround hack; not a language feature</center></Rect>
	</DarkSection>
	<DarkSection>
		<Rect invert><h4>Can we have simply implement comments?</h4></Rect>
		<Rect>
			<p> Maybe make <code>#</code> a keyword to create a comment line or block?</p>
			<p className="fragment">But it will violate our founder's language design principle.</p>
			<Notes inline>
				Which states, content not essential to understanding logic of the code should not expand the code vertically.
				This is why we have <a href="https://darklang.github.io/docs/unique-aspects#functions-that-use-error-rail"
					target="_blank" rel="noreferrer">Error Rails</a>, instead of letting users do their own error handling within the main
				body.</Notes>

			<p className="fragment"><em>How can we allow for users to add comments to their code?</em></p>
		</Rect>
	</DarkSection>
	<DarkSection>
		<Rect>
			<ul>People use comments for 2 reasons:

				<li>To communicate about a complex chunk of code in a natural language.</li>
				<li>To temporarily disable a chunk of code during execution. <Notes inline>This can already be
					achieved with <a href="https://darklang.github.io/docs/feature-flags" target="_blank" rel="noreferrer">Feature Flags</a>,
					which we have. And I'll talk more about those later on.
				</Notes>
				</li>
			</ul>
			<Notes>Let's
				focus on the first use case adding, meta-information about the code to communicate with another person reading
				it.
			</Notes>
		</Rect>
	</DarkSection>
	<DarkSection light>
		<h3>Arriving at a solution</h3>
		<Notes>Dark has borrowed many ideas from document editor experiences. I think like Airtable is
			trying to bring the power of databases to the general public by making it appear like a spreadsheet. We aims
			to democratize coding by borrowing rich text editing experiences; instead of solely deriving from the text
			editors developers use.</Notes>
		<p>Why don't we take page from collaborative document editing software?</p>
		<div className="fragment">
			<Image
				file="teamwork-2-b.png"
				alt="commenting on Google Docs" />
			<Notes>The comments will appear to the side of the document without obstructing the reading
				experience of the main document.</Notes>
		</div>
	</DarkSection>
	<DarkSection light={true}>
		<h3>Solution</h3>
		<p className="center">When the user selects a code chunk they can add a comment to it.</p>
		<Image className={css(s.lightImg)}
			file="select_code.png"
			alt="User selects code to comment" />
	</DarkSection>
	<DarkSection light={true}>
		<p className="center">They can type in a comment and collapse to hide it.</p>
		<Image className={css(s.lightImg)}
			file="comment-opened.png"
			alt="Add comment" />
	</DarkSection>
	<DarkSection light={true}>
		<p className="center">Collapsed comment will just show as a marker, and be read on click.</p>
		<Image file="comment-collapsed.png"
			className={css(s.lightImg)}
			alt="Collapsed comment" />
	</DarkSection>

	<DarkSection>
		<Rect invert><h2>Solving: Who wrote this?!</h2></Rect>
		<Rect>
			<q>When users are confused about some of the code they are reading, they should be able to find out who
				to ask for
				help.</q>
		</Rect>
	</DarkSection>
	<DarkSection>
		<App isShow={isSlideShow} title="Users working on a code block">
			<Notes>With the popular demand for collaboration features, we have the ability to see other users who are on the
				same project.</Notes>
			<Image file="teamwork-1-b.png" center={!isSlideShow}
				alt="Anoter user's avatar active on the code block" /></App>
		<Rect>
			<Notes>If another user is editing the a code block on the same project space you are on, their
				avatar will show to the right.</Notes>
			<p>But this feature only shows the user what is happening right now. <em>What if they want to gaze into the
				past?</em> How can they do that?</p></Rect>
	</DarkSection>

	<DarkSection>
		<Rect invert><h3>Space Constraints</h3></Rect>
		<App isShow={isSlideShow} title="Many things off to the side">
			<Notes>Can we just keep the avatars there? Even if a users is not active? How can we show that information without
				adding more clutter?</Notes>
			<Image file="teamwork-1-a.png"
				alt="Space constraitns around a code block" />
			<Notes>All around your code, there's already alot going on! Above is the documentation box. To the
				left is live values, the right side has references
				(and also sometimes feature flags), below is the evaluated return value. For over a year our product has been
				struggling with limited dimensionality. In multiple instances, colleagues and I have been proposing having
				different modes in which only certain things will be visible.</Notes>
		</App>
	</DarkSection>
	<DarkSection>
		<Rect invert special><h3>Arriving at a solution</h3></Rect>
		<Rect special>
			Borrowing from image editing software...<br /><br />

			<q><em>What about layers!</em> As we add more features to Dark, we must give affordances for users to
				see different things, when performing different tasks. Not everything needs to be on the screen at all once.
				Layers help us reduce visual noise and addresses our running out of space problem.
			</q>
		</Rect>
	</DarkSection>
	<DarkSection light={true}>
		<Image className={css(s.lightImg)}
			file="story-2.png"
			alt="Idea of layers on project canvas" />
		<p className="center">By activating the change log layer, users can see who edit last.</p>
		<Notes>You probably wonder is this accurate? In Dark since users are making changes to the AST
			instead of text representations, we are
			saved from "blame the person who installed the linter" problem.</Notes>
	</DarkSection>

	<DarkSection>
		<Rect invert><h2>Solving: How do I edit code in chunks?</h2></Rect>
		<Rect>
			<Notes>All Dark code is already in production, everything a user writes is immediately deployed.
				There are no local or staging environments.</Notes>
			<q>Users need to be able to make changes to their codebase without affecting their customers
				immediately. And they need to package their changes for another to review or deploy.</q>
		</Rect>
	</DarkSection>
	<DarkSection>
		<Rect invert special><h3>Arriving at a solution</h3></Rect>
		<App isShow={isSlideShow} title="Feature flags">
			<Notes>Dark lacks the traditional git branches in most contemporary code bases. It is
				difficult for users to make changes in their code base that won't immediately affect production.</Notes>
			<p><a href="https://darklang.github.io/docs/feature-flags" target="_blank" rel="noreferrer">Feature Flags</a> provide a method
				users can change and test out their code, before enabling it for all their users. But as of now, they can only
				flag individual chunks of code.</p>
			<Image file="teamwork-3-a.png"
				alt="Feature flags" /></App>
	</DarkSection>
	<DarkSection>
		<Rect><p>But new features or fixes often span across multiple parts of the codebase.</p>

			<Notes>For example, let's say they add a new field to a database. They have to update POST
				endpoints to include the new field as input, as well as anywhere that performs <code>SELECT *</code> on the
				schema will
				have to handle the new column being fetched.</Notes>
		</Rect>
	</DarkSection>
	<DarkSection>
		<Rect special>
			<q>How can we extend feature flags to group changes together and allow someone to review all changes
				associated with the new feature?</q>
			<br /><br />
			<ol>To do so it would need to do two things:
				<li>Provide support for batching changes under a single feature flag.</li>
				<li className="fragment">Provide support to review the changes in a feature flag<Notes inline>,
					UI to request changes or approve changes. And also support for deploying them.</Notes>
				</li>
			</ol>
		</Rect>
	</DarkSection>
	<DarkSection light={true}>
		<h3>Solution</h3>
		<p className="center">When the user select a semantically valid code chunk, they can feature flag it!</p>
		<Image className={css(s.lightImg)}
			file="select_code.png"
			alt="Select code to feature flag" />
	</DarkSection>
	<DarkSection light={true}>
		<p className="center">The code in the feature flag will only execute for fellow teammates who enabled this feature
			flag. Their customer will run on the production program.</p>
		<Image className={css(s.lightImg)} file="ff-new.png"
			alt="Code copied over to feature flag to be edited" />
	</DarkSection>
	<DarkSection light={true}>
		<p className="center">The name field autocompletes with names of active flags. Or the user can
			enter a unique name to create a new feature flag.</p>
		<Image className={css(s.lightImg)} file="story-4.png"
			alt="Can add onto existing feature flag" />
	</DarkSection>
	<DarkSection light={true}>
		<p className="center">Multiple users can make changes to the same feature flag</p>
		<Image className={css(s.lightImg)} file="ff-multiusers.png"
			alt="See all changes on a feature flag" />
		<Notes>Since Dark's code is all online, multiple users can add to the same feature flag without
			having to push and pull branches. When they are working on a FF with multiple changes, a list of all the
			changes in that FF pops up.</Notes>
	</DarkSection>
	<DarkSection light={true}>
		<div className="center">
			<Notes inline>Once all changes has been agreed upon by the team. </Notes>The feature flag is ready
			to go live!</div>
		<Image className={css(s.lightImg)} file="story-9.png"
			alt="Deploy feature flag" />
	</DarkSection>
	<DarkSection>
		<Rect invert><h3>Go live! (Rollout)</h3></Rect>
		<Rect>
			<q>Rollout is the proccess in with code in the feature flag replaces existing code. After rollout is
				complete the flag becomes inactive</q></Rect>
		<Rect special>
			<ul>There are several different rollout options:
				<li className="fragment">Gradual rollout<Notes inline> will first execute this code for a certain
					percent of grandusers, and slowly increasing the percent until 100%. At any point during this rollout
					process, if reports of errors or other problems occur, you may pause this rollout process.</Notes>
				</li>
				<li className="fragment">Scheduled rollout<Notes inline> will have all 100% of grandusers use the
					new code at a specific date-time. ie: The hour of a week when granduser traffic is at lowest.</Notes>
				</li>
				<li className="fragment">Immediate rollout<Notes inline> will have Dark deploy the new code ASAP as
					soon as the user clicks the button.</Notes>
				</li>
			</ul>
		</Rect>
	</DarkSection>

	<DarkSection>
		<Rect special invert><h2>Putting it all together</h2></Rect>
		<Notes>Now let's see how these three solutions work together in an engineering team scenario.
		</Notes>

		<Rect special>
			<p style={{ textAlign: "left" }}>New developer Ryan joins the team and is tasked to update the registration
				flow. <br /><br />

				<b>Feature Request:</b> After users sign up, send them a slack invite code in their welcome email.
			</p></Rect>
	</DarkSection>
	<DarkSection light={true}>
		<div>Ryan opens the project's space. <Notes inline> They read the code and comments other users
			had annotated.</Notes>
		</div>
		<Image className={css(s.lightImg)}
			file="story-1.png"
			alt="Reads comment on code"
			height="500" />
	</DarkSection>
	<DarkSection light={true}>
		<div>
			<Notes inline>But there is one part that is an extremely complex part.</Notes> They see Tatianna
			wrote most of it.
		</div>
		<Image className={css(s.lightImg)}
			file="story-2.png"
			alt="See change log on code" height="500" />
	</DarkSection>
	<DarkSection light={true}>
		<div>
			<Notes inline>Ryan reaches out to Tatianna, and she walks them through the code. </Notes>
			Tatianna helps Ryan. She shows them how to create a feature flag. They pair together to create a fix.
		</div>
		<Image className={css(s.lightImg)}
			file="story-3.png"
			alt="Select to feature flag code" height="500" />
	</DarkSection>
	<DarkSection light={true}>
		<div>Ryan adds more changes on top of Tatianna's feature flag</div>
		<Image className={css(s.lightImg)}
			file="story-4.png"
			alt="Add changes to feature flag" height="500" />
	</DarkSection>
	<DarkSection light={true}>
		<div>Ryan asks Adam to review the "slack integration" feature flag.</div>
		<Image className={css(s.lightImg)}
			file="story-5.png"
			alt="Send feature flag to be reviewed" height="500" />
	</DarkSection>
	<DarkSection light={true}>
		<div>Adam gets a notification about a new feature flag, and goes to review it.</div>
		<Image className={css(s.lightImg)}
			file="story-6.png"
			alt="Request changes to feature flagged code" height="500" />
	</DarkSection>
	<DarkSection light={true}>
		<div>Adam rejects the feature flag, requesting additional changes to Ryan's code.</div>
		<Image className={css(s.lightImg)}
			file="story-7.png"
			alt="Suggest changes to feature flagged code" height="500" />
	</DarkSection>
	<DarkSection light={true}>
		<div>Ryan makes suggested changes, and ask Adam to look at it again.</div>
		<Image className={css(s.lightImg)}
			file="story-8.png"
			alt="Review suggested changes" height="500" />
	</DarkSection>
	<DarkSection light={true}>
		<div>Adam finally approves, and sets a rollout plan for midnight on Sunday.</div>
		<Image className={css(s.lightImg)}
			file="story-9.png"
			alt="Deploy code" height="500" />
		<div className="font-pink">Everyone is delighted and go out for Happy Hour!</div>
	</DarkSection>
	<DarkSection>
		<Rect invert special><h2>Future Considerations</h2></Rect>
		<Rect special>
			<ul>I cut scope on this to get out the best ideas within a day. There are additional considerations that would
				be taken into account in the next phases:
				<li>Who has permission to review?</li>
				<li>How do we reverse or halt the deployment of a feature flag?</li>
				<li>Will users want stricter permissions on who can amend to another's feature flag?</li>
				<li>What should be the UI for gradual and scheduled rollout?</li>
			</ul>
		</Rect>
	</DarkSection>

</>);

export default content;