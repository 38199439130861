import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { StyleSheet, css } from 'aphrodite/no-important';
import { sizes, styles as appStyles } from '../bits/sharedStyles'
import { LockIcon } from '@primer/octicons-react'

const companies = {
    "Dark": "https://www.darklang.com",
    "Lynxtool": "https://www.lynxtool.com",
    "General Assembly": "https://generalassemb.ly/",
    "Ironhack": "https://www.ironhack.com",
}

const roleColors = {
    "UI design": "var(--bg-jelly)",
    "development": "var(--bg-peach)",
    "research": "var(--bg-lime)",
    "testing": "var(--bg-cherry)",
    "UX design": "var(--bg-violet)",
    "prototype": "var(--bg-orange)"
}

const skillColors = {
    "WebGL": "var(--bg-cherry)",
    "GLSL": "var(--bg-peach)",
    "Prinicple": "var(--bg-indigo)",
    "After Effects": "var(--bg-violet)",
    "AR": "var(--bg-lemon)",
    "Framer": "var(--bg-jelly)",
};

const data = [
    {
        title: "Visualizing switches",
        img: "lynxif/th.gif",
        path: "lynx/if",
        company: "Lynxtool",
        roles: ["UI design", "development"],
        description: "Giving different answers based on different conditions is fundamental to computation. But how do we visualize this concept to a user who may not be familiar with boolean logic, circuit diagrams, or even flow charts?",
        protected: true
    },
    {
        title: "Onboarding with tooltips",
        img: "darktips/th.png",
        path: "dark/tooltips",
        company: "Dark",
        roles: ["research", "UI design", "testing"],
        description: "Our target users are exploratory learners. They are poking around, unafraid of trying even if they don't know what they are doing. How do can we aid them in their exploration so they may successfully build their vision in Dark?",
        protected: false
    },
    {
        title: "Engineering collaboration",
        img: "darkteams/th.png",
        path: "dark/teams",
        company: "Dark",
        roles: ["UX design", "UI design", "prototype"],
        description: "Dark initially was built for one user. But developers typically work in teams, and we struggled to get larger projects onboard because we didn't have the features necessary for teams to work on the same project. What features will we need to make that possible?",
        protected: false
    },
    {
        title: "Navigating in your codebase",
        img: "darkflow/th.png",
        path: "dark/flow",
        company: "Dark",
        roles: ["UX design", "UI design", "testing", "prototype"],
        description: "Help users get around their projects, and see how different parts are related.",
        protected: false
    },
]

const courses = [
    {
        title: "Exploring Expressive Therapy",
        img: "article_saxo.jpg",
        path: "https://medium.com/@aliceaskwhy/exploring-expressive-therapy-125268112625",
        company: "Ironhack",
        roles: ["research", "UX design", "testing", "UI design", "prototype"],
        description: `Therapy sessions can be expensive, how might we provide the benefits of expressive therapy in the comfort
        of your own space for free?`,
        protected: false
    },
    {
        title: "Simulating Serendipity",
        img: "article_jush.jpg",
        path: "https://medium.com/@aliceaskwhy/simulating-serendipity-98c0a9896326",
        company: "General Assembly",
        roles: ["research", "UX design", "prototype"],
        description: `How do we connect people through shared experiences? A re-design of the dating app "How About We".`,
        protected: false
    },

];

const experiments = [
    {
        title: "Shade me with words",
        img: "th_exp_wordart.gif",
        path: "lab/wordshaders",
        skills: ["WebGL", "GLSL"],
        description: "Used shaders to mask multiple texture layers to create a composition image, made out of words at variable density."
    },
    {
        title: "Portal Rendering",
        img: "th_exp_portalcube.png",
        path: "lab/portalcube",
        skills: ["WebGL"],
        description: "Proof of concept to create MC Escher's Another World using portal rendering."
    },
    {
        title: "A day in Hong Kong",
        img: "th_exp_hk.png",
        path: "lab/hk",
        skills: ["Prinicple", "After Effects", "AR"],
        description: "First AR project using Artivive's editor, prototyped in an afternoon."
    },
    {
        title: "Tumblr-style interest onboarding",
        img: "th_exp_onboarding.gif",
        path: "lab/framer/onboard",
        skills: ["Framer"],
        description: "Tumblr style onboarding with Framer"
    },
    {
        title: "Tumblr-style FAB animation",
        img: "th_exp_fab.gif",
        path: "lab/framer/fab",
        skills: ["Framer"],
        description: "Tumblr style Floating action button to create post with Framer"
    },
]

const s = StyleSheet.create({
    wrapper: {
        marginLeft: `${sizes.sectionSpace / 2}px`,
        "@media (min-width: 768px)": {
            marginLeft: 0
        }
    },
    projectLink: {
        textDecoration: 'none',

        display: "flex",
        flexDirection: "column",
        "@media (min-width: 768px)": {
            display: "grid",
            gridTemplateColumns: "3fr 2fr",
            gridTemplateRows: "auto auto 2fr",
            gap: `${sizes.pad}px`,
            gridAutoFlow: "row",
            gridTemplateAreas: `
                "title title"
                "roles image"
                "about image"`,
        },
        ":hover": {
            cursor: "pointer",
        }
    },
    courseLink: {
        color: 'var(--color-dark)',
        textDecoration: 'none',

        display: "flex",
        flexDirection: "column",
        "@media (min-width: 768px)": {
            display: "grid",
            gridTemplateColumns: "2fr 3fr",
            gridTemplateRows: "auto auto 2fr",
            gap: `${sizes.pad}px`,
            gridAutoFlow: "row",
            gridTemplateAreas: `
                "title title"
                "image roles"
                "image about"`,
        },
        ":hover": {
            cursor: "pointer",
            color: 'var(--color-dark)'
        }
    },
    cardShadow: {
        boxShadow: `${sizes.pad}px ${sizes.pad}px 0 var(--color-accent),
        ${sizes.pad}px ${sizes.pad}px 0 1px var(--color-dark-grey)`,
        transition: 'box-shadow 0.2s',
        ':hover': {
            boxShadow: `0 0 0 ${0.5 * sizes.pad}px var(--color-accent),
                0 0 0 ${0.5 * sizes.pad + 1}px  var(--color-dark-grey)`,
        }
    },
    oddShadow: {
        boxShadow: `${sizes.pad}px ${sizes.pad}px 0 var(--color-primary),
        ${sizes.pad}px ${sizes.pad}px 0 1px var(--color-dark-grey)`,
        transition: 'box-shadow 0.2s',
        ':hover': {
            boxShadow: `0 0 0 ${0.5 * sizes.pad}px var(--color-primary),
                0 0 0 ${0.5 * sizes.pad + 1}px  var(--color-dark-grey)`,
        }
    },
    image: {
        background: "#aaa",
        width: `100%`,
        border: '2px solid var(--color-light-grey)',
        margin: `${sizes.pad}px 0`,
        "@media (min-width: 768px)": {
            width: "300px",

            gridArea: "image"
        }
    },
    title: {
        color: 'var(--color-dark-accent)',
        "@media (min-width: 768px)": {
            gridArea: "title"
        }
    },
    roles: {
        "@media (min-width: 768px)": {
            gridArea: "roles"
        }
    },
    about: {
        color: `var(--color-dark)`,
        "@media (min-width: 768px)": {
            gridArea: "about"
        }
    },
    roleTag: {
        display: 'inline-block',
        fontSize: '0.8em',
        padding: '4px',
        marginRight: '1ch',
        color: 'var(--color-dark)'
    }
})

export const renderRole = (role) => (<span className={css(s.roleTag)} style={{ backgroundColor: roleColors[role] }} key={role}>{role}</span>);

const renderSkill = (role) => (<span className={css(s.roleTag)} style={{ backgroundColor: skillColors[role] }} key={role}>{role}</span>);

export const ProjectCard = ({ project: p, odd }) => {
    const nav = useNavigate();
    return (<div
        className={css(appStyles.rect, appStyles.lightBox, odd ? s.oddShadow : s.cardShadow)}
        onClick={(evt) => { nav(`/projects/${p.path}`) }}>
        <div className={css(s.projectLink)}>
            <h3 className={css(s.title)}>{p.protected && <LockIcon size={24} />}{p.title} @ <a href={companies[p.company]} target="_blank" rel="noreferrer">{p.company}</a></h3>
            <img src={process.env.PUBLIC_URL + '/' + p.img} alt={p.title} className={css(s.image)} />
            <p className={css(s.roles)}>{p.roles.map(renderRole)}</p>
            <p className={css(s.about)}>{p.description}</p></div>
    </div>);
}

const content = () => (<div className={(css(s.wrapper))}>{data.map((p, i) => <ProjectCard project={p} key={i} odd={i % 2 !== 0} />)}</div>);
export default content;

export const CourseCard = ({ project: p, odd }) => {
    return (<div
        className={css(appStyles.rect, appStyles.lightBox, odd ? s.oddShadow : s.cardShadow)}>
        <a href={p.path} className={css(s.courseLink)} target="_blank" rel="noreferrer">
            <h3 className={css(s.title)}>{p.title} @ <a href={companies[p.company]} target="_blank" rel="noreferrer">{p.company}</a></h3>
            <img src={process.env.PUBLIC_URL + '/' + p.img} alt={p.title} className={css(s.image)} />
            <p className={css(s.roles)}>{p.roles.map(renderRole)}</p>
            <p className={css(s.about)}>{p.description}</p></a>
    </div>);
}

export const CourseProjects = () => (<div className={(css(s.wrapper))}>{courses.map((p, i) => <CourseCard project={p} key={i} odd={i % 2 === 0} />)}</div>)

export const Lab = () => (<div className={(css(s.wrapper))}>{experiments.map((p, i) => (
    <div
        className={css(appStyles.rect, appStyles.lightBox, (i % 2 === 0) ? s.oddShadow : s.cardShadow)}>
        <a href={p.path} className={css(s.projectLink)}>
            <h3 className={css(s.title)}>{p.title}</h3>
            <img src={process.env.PUBLIC_URL + '/' + p.img} alt={p.title} className={css(s.image)} />
            <p className={css(s.roles)}>{p.skills.map(renderSkill)}</p>
            <p className={css(s.about)}>{p.description}</p></a>
    </div>
))}</div >);