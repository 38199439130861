import { StyleSheet } from 'aphrodite/no-important';
import Layout from "../bits/Layout";
import { Rect, App, SideBySide } from '../bits/sharedStyles';

const styles = StyleSheet.create({
  frameBg: {
    backgroundColor: "black"
  }
});

const Page = () => (<Layout still wide>
  <SideBySide>
    <App title="Shade me with words" contentStyles={[styles.frameBg]} fullBleed>
      <iframe title="Shade me with words" src="https://9ae.netlify.app/shaderwords" width="400" height="600" style={{ border: "none" }}></iframe></App>
    <div>
      <Rect invert><h2>Masking with GLSL</h2></Rect>
      <Rect>
        <p>These portrait renditions are composed of words at different densities. I initally made this <a href="https://github.com/9ae/wordart" target="_blank" rel="noreferrer">project in context2d</a>, but that required a for-loop going over each pixels. Here we instead optimize by letting the fragment shader do most of our work in figuring out which texture.</p>
      </Rect>
    </div>
  </SideBySide>
</Layout>);

export default Page;