import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./pages/App";

import Projects from './pages/Projects';
import Me from './pages/Me';

import ShowLynxIf from './pages/ShowLynxIf';
import ReadLynxIf from './pages/ReadLynxIf';
import ReadDarkTips from './pages/ReadDarkTips';
import ReadDarkTeams from './pages/ReadDarkTeams';
import ReadDarkFlow from './pages/ReadDarkFlow';
import ShowDarkTips from './pages/ShowDarkTips';
import ShowDarkTeams from './pages/ShowDarkTeams';
import ShowDarkFlow from './pages/ShowDarkFlow';
import Resume from './pages/Resume';
import RequestPassword from './pages/RequestPassword';
import GotRequest from './pages/GotRequest';
import LabWordShaders from './pages/ExpWordShaders';
import LabPortalCube from './pages/ExpPortalCube';
import LabHK from './pages/ExpArHk';
import LabFab from './pages/ExpTumblrFab';
import LabOnboard from './pages/ExpTumblrOnboard';

import ShowDatadog from './pages/ShowDatadog';

import Style from './pages/Style'

import "./index.css";

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="projects" element={<Projects />} />
      <Route path="resume" element={<Resume />} />
      <Route path="me" element={<Me />} />

      <Route path="projects/lynx/if" element={<ReadLynxIf />} />
      <Route path="show/lynx/if" element={<ShowLynxIf />} />

      <Route path="projects/dark/tooltips" element={<ReadDarkTips />} />
      <Route path="show/dark/tooltips" element={<ShowDarkTips />} />
      <Route path="projects/dark/teams" element={<ReadDarkTeams />} />
      <Route path="show/dark/teams" element={<ShowDarkTeams />} />
      <Route path="projects/dark/flow" element={<ReadDarkFlow />} />
      <Route path="show/dark/flow" element={<ShowDarkFlow />} />

      <Route path="lab/wordshaders" element={<LabWordShaders />} />
      <Route path="lab/portalcube" element={<LabPortalCube />} />
      <Route path="lab/hk" element={<LabHK />} />
      <Route path="lab/framer/fab" element={<LabFab />} />
      <Route path="lab/framer/onboard" element={<LabOnboard />} />

      <Route path="request" element={<RequestPassword />} />
      <Route path="request/ok" element={<GotRequest />} />

      {/* <Route path="present/coda" element={<ShowCoda />} /> */}
      <Route path="show/woof" element={<ShowDatadog />} />

      <Route path="style" element={<Style />} />
    </Routes>
  </BrowserRouter>,
  rootElement
);