import { useEffect, useRef } from "react";
import * as THREE from "three";

import Header from "../bits/Header";
import Footer from "../bits/Footer";
import { StyleSheet, css } from 'aphrodite/no-important';

import { sizes, } from '../bits/sharedStyles';

const styles = StyleSheet.create({
  content: {
    color: `var(--color-dark)`,

    zIndex: 1,

    margin: `${sizes.sectionSpace}px`,
    marginLeft: `${sizes.sectionSpace / 2}px`,
    paddingBottom: `${2 * sizes.sectionSpace}px`,
    minHeight: "90vh",

    "@media (min-width: 768px)": {
      maxWidth: "700px",
      margin: `${sizes.sectionSpace}px auto`,
    },
  },
  wide: {
    width: `calc(100% - ${2 * sizes.sectionSpace}px)`,
    "@media (min-width: 768px)": {
      maxWidth: "1000px",
    }
  },
  canvas: {
    zIndex: -1,
    position: "fixed",
    top: 0,
    left: 0,
  },

  stillBg: {
    backgroundPosition: '0 0, top 250px right',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("${process.env.PUBLIC_URL}/bg-tl.png"), url("${process.env.PUBLIC_URL}/bg-right.png")`,
    backgroundSize: '300px 300px',
    width: '100vw',
    height: '100vh',

    "@media (min-width: 768px)": {
      backgroundImage: `url("${process.env.PUBLIC_URL}/bg-tl@2x.png"), url("${process.env.PUBLIC_URL}/bg-right@2x.png")`,
      backgroundSize: '600px 600px',
    }
  }
})

// const nudgeRandom = (max) => Math.floor(Math.random()*max*2 - max);
const randomBetween = (min, max) => {
  return Math.random() * (max - min + 1) + min;
}
const plusOrMinus = () => Math.random() > 0.5 ? 1 : -1;


const Page = ({ children, wide = false, still = false }) => {
  const mountRef = useRef(null);

  useEffect(() => {
    if (still) return;

    const canvasWrapper = mountRef.current;
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xfbfaf8);
    const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    canvasWrapper.appendChild(renderer.domElement);

    scene.add(new THREE.AmbientLight(0xeaeaea));

    const light = new THREE.PointLight(0xaeaeae, 0.5, 100);
    light.position.set(0, 20, 0);
    scene.add(light);

    window.addEventListener('resize', function () {
      var width = window.innerWidth;
      var height = window.innerHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    }, false);

    const colors = [0xFDABAB, 0xFDC9AB, 0xFDEBAB, 0xE3FDAB, 0xACFDB3, 0xACF3FC, 0xACC2FC, 0xC1ABFC, 0xFDABEA].map(color => (new THREE.MeshLambertMaterial({ color })));

    const geometry = new THREE.TetrahedronGeometry();
    const donutHole = 2;
    const spaceRadius = 15;
    const yRange = 7;
    for (var i = 0; i < 21; i++) {
      const dot = new THREE.Mesh(geometry, colors[i % colors.length]);
      dot.position.y = randomBetween(donutHole, yRange);
      dot.position.x = randomBetween(donutHole, spaceRadius) * plusOrMinus();
      dot.position.z = randomBetween(donutHole, spaceRadius) * plusOrMinus();
      const sz = Math.random() + 0.5;
      dot.scale.set(sz, sz, sz);
      dot.rotation.x = Math.random() * Math.PI;
      dot.rotation.y = Math.random() * Math.PI;
      dot.rotation.z = Math.random() * Math.PI;
      scene.add(dot);
    }

    const cameraRadius = 10;
    let angle = 0;
    camera.position.y = cameraRadius * -0.7;
    camera.position.x = cameraRadius * Math.cos(angle) + cameraRadius * Math.sin(angle);
    camera.position.z = cameraRadius * Math.cos(angle) - cameraRadius * Math.sin(angle);
    camera.lookAt(0, 0, 0);

    const angleDelta = .001;
    let scrollY = window.scrollY;
    window.addEventListener('scroll', (evt) => {
      const delta = (window.scrollY - scrollY) * angleDelta;
      angle += delta;
      if (angle > Math.PI * 2) {
        angle = 0;
      }

      camera.position.x = cameraRadius * Math.cos(angle) + cameraRadius * Math.sin(angle);
      camera.position.z = cameraRadius * Math.cos(angle) - cameraRadius * Math.sin(angle);
      camera.lookAt(0, 0, 0);

      scrollY = window.scrollY;
    })

    function animate() {
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    animate();

    return () => canvasWrapper.removeChild(renderer.domElement);
  });

  return (
    <>
      <div ref={mountRef} className={css(styles.canvas, still && styles.stillBg)}></div>
      <Header />
      <main className={css(styles.content, wide && styles.wide)}>{children}</main>
      <Footer />
    </>
  );
}

export default Page;
