import * as React from "react"
// import { useSearchParams } from "react-router-dom";
import { StyleSheet, css } from 'aphrodite/no-important';

import Layout from "../bits/Layout";
import ContactCard from "../bits/ContactCard";
import { WorkExperience, Education, Skills } from '../content/exp'
import { sizes, App, Rect, buttonStyle } from "../bits/sharedStyles";

const s = StyleSheet.create({
    colsContainer: {
        display: "grid",
        gridTemplateColumns: "300px auto",
        gridTemplateRows: "230px auto",
        gridTemplateAreas: `"card card"
        "main main"
        "side side"`,
        gridGap: `${3 * sizes.pad}px`,
        justifyItems: "stretch",
        alignItems: "stretch",

        "@media (min-width: 768px)": {
            gridTemplateAreas: `"card main"
            "side main"`,
            gridGap: `${sizes.pad}px ${4 * sizes.pad}px`,
        }
    },
    gridCard: {
        gridArea: 'card'
    },
    gridMain: {
        gridArea: 'main',
        backgroundColor: "var(--color-light)",
    },
    gridSide: {
        gridArea: 'side'
    },

    name: {
        marginTop: 0,
        color: 'var(--color-dark-primary)',
        textAlign: "center"
    },

    title: {
        marginTop: 0,
        textAlign: "center",
        tableLayout: "fixed",
    },

    table: {
        //  padding: "1.0em",
        borderSpacing: "1.0em",
    },

    cellCard: {
        verticalAlign: 'top',
        width: "2.5in",
        height: "fit-content",
        // fontSize: "8pt"
    },
    cellSide: {
        verticalAlign: 'top',
    },
    banner: {
        display: "flex",
        gap: `${3 * sizes.pad}px`,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",

        "@media (min-width: 768px)": {
            height: "50px",
            flexDirection: "row",
        }
    },

    bannerText: {
        display: "inline-block",
        "@media (min-width: 768px)": {
            fontSize: "1.2em",
        }
    }

})


function Page() {
    return (<Layout wide>
        <Rect special style={{ width: "100%" }}><div className={css(s.banner)}>
            <span className={css(s.bannerText)}>Want printable the format?</span>
            <a href={`${process.env.PUBLIC_URL}/resume.pdf`} className={buttonStyle} target="_blank" rel="noreferrer">Get PDF Version</a></div>
        </Rect>
        <div className={css(s.colsContainer)}>
            <div className={css(s.gridCard)}>
                <App>
                    <h1 className={css(s.title, s.name)}>Alice Q. Wong</h1>
                    <p className={css(s.title)} >Product Designer <br />& UX Engineer</p>
                    <ContactCard valueColor='var(--color-dark)' labelColor='var(--color-dark-primary)' />
                </App>
            </div>
            <div className={css(s.gridMain)}>
                <WorkExperience />
            </div>
            <div className={css(s.gridSide)}>
                <Rect>
                    <Education />
                    <Skills />
                </Rect>
            </div>
        </div>
    </Layout>);
}

export default Page;