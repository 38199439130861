import Layout from "../bits/Layout";
import { VSpacer } from "../bits/spacer";
import Content, { CourseProjects, Lab } from '../content/projects';

const anchorOverride = {
    color: "var(--color-dark)",
    textDecoration: "none",
}

const page = () => (<Layout>
    <a name="work" href="#work" style={anchorOverride}><h1>Professional work</h1></a>
    <p>I am improving my design skills through the Future of Programming community. Using my background as a full-stack engineer and physics tutor, I want to make computation more accessible. Below are some projects I've done at Dark (a backend IDE and language for non-backend developers) and at Lynxtool (a node editor to transform large data sets visually).</p>
    <VSpacer />
    <Content />

    <VSpacer big />

    <a name="class" href="#class" style={anchorOverride}><h1>Course work</h1></a>
    <p>I've saved money to take courses in user experience and design during my engineering career.</p>
    <VSpacer />
    <CourseProjects />

    <VSpacer big />

    <a name="labs" href="#labs" style={anchorOverride}><h1>Experiments</h1></a>
    <p>I am interested in using computation for art. Most of my hobby coding are just graphics programming experiments.</p>
    <VSpacer />
    <Lab />

</Layout>);

export default page;