import React from "react"
import PropTypes from "prop-types"

const Notes = ({children, inline}) => {
    let classes = 'notes';
    if (inline){ classes += ' inline'}
    return (<aside className={classes}>{children}</aside>)};

Notes.propTypes = {
    children: PropTypes.node.isRequired,
    inline: PropTypes.bool
}

Notes.defaultProps = {
    inline : false
}

export default Notes;