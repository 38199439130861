import Layout from "../bits/Layout";
import { Rect, App } from '../bits/sharedStyles';

const Page = () => (<Layout still>
  <App title="Portal rendering (use mouse to rotate camera & zoom)" fullBleed>
    <iframe title="Portal rendering" src="https://9ae.netlify.app/portalcube" style={{ border: "none", width: "100%", minWidth: "320px", height: "500px" }}></iframe></App>
  <div>
    <Rect invert><h2>Multi-camera projections rendering on texture</h2></Rect>
    <Rect>
      <p>The game Portal achieved the feeling that you are looking through a portal into another space by placing multiple cameras around the scene and rendering the results of the camera's viewport unto a texture. Coincidentally this is also how we can recreate <a href="https://en.wikipedia.org/wiki/Another_World_(M._C._Escher)" target="_blank" rel="noreferrer">M.C. Escher's "Another World"</a> in digital 3d space.</p>
    </Rect>
  </div>
</Layout>);

export default Page;