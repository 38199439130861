import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';

import { sizes, Dot, BaseRect, BaseSticky, BaseApp, SideBySide } from "../bits/sharedStyles";
import Notes from "../bits/Notes";

const lineBorder = `1px solid var(--lynx-grey3)`;
const procreateBGColor = "#cdcdcd";

const centerFlex = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const vAlignFlex = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}

export const styles = StyleSheet.create({
  white: {
    backgroundColor: "white",
  },
  procreate: {
    backgroundColor: "#cdcdcd",
  },
  allBorder: {
    border: lineBorder,
  },
  bottomBorder: {
    borderBottom: lineBorder,
  },
  bgWhite: {
    backgroundColor: 'var(--lynx-light1)'
  },
  bgGreen: {
    backgroundColor: 'var(--lynx-green0)',
    color: 'var(--lynx-dark1) !important',
  },
  bgOrange: {
    backgroundColor: 'var(--lynx-orange0)',
    color: 'var(--lynx-dark1) !imporant',
  },
  bgYellow: {
    backgroundColor: 'var(--lynx-yellow)',
  },
  bgGrey: {
    backgroundColor: 'var(--lynx-grey1)',
  },
  centerContent: centerFlex,
  appWindow: {
    backgroundColor: 'var(--lynx-grey1)',
    border: lineBorder,
    boxShadow: `${sizes.pad}px ${sizes.pad}px 0 var(--lynx-light1),
        ${sizes.pad}px ${sizes.pad}px 0 1px var(--lynx-grey3)`,
  }
});

const Image = ({ file, alt, center, ...props }) => {

  const image = (<img
    src={`${process.env.PUBLIC_URL}/lynxif/${file}`}
    alt={alt} {...props} />);

  if (center) {
    return (<center>{image}</center>);
  }

  return image;
};

export const Rect = ({ children, style, invert, special, otherBG, isReading }) => {

  if (isReading) {
    return (<div style={style}>{children}</div>);
  }

  const accent = special ? 'var(--lynx-orange0)' : 'var(--lynx-green0)';
  const base = otherBG ?? 'var(--lynx-light1)';

  let newStyle = {
    ...style,
    backgroundColor: invert ? accent : base,
    color: 'var(--lynx-dark1)',
    border: lineBorder,
    boxShadow: `${sizes.pad}px ${sizes.pad}px 0 ${invert ? base : accent}, 
        ${sizes.pad}px ${sizes.pad}px 0 1px var(--lynx-grey3)`
  }

  return (<BaseRect style={newStyle}>{children}</BaseRect>);
}

const App = ({ children, title }) => (
  <BaseApp
    title={title}
    windowStyles={[styles.appWindow]}
    titleStyles={[styles.bgWhite, styles.bottomBorder]}
    dotColors={[styles.bgGreen, styles.bgYellow, styles.bgOrange]}
  >{children}</BaseApp>);

const Sticky = ({ children, styles, fragment }) => (<BaseSticky fragment={fragment} style={{
  backgroundColor: 'var(--lynx-yellow)',
  color: 'var(--lynx-dark1)',
  boxShadow: `${sizes.pad / 2}px ${sizes.pad / 2}px 0 var(--lynx-grey3)`,
  ...styles
}}>{children}</BaseSticky>);

const content = ({ isSlideShow }) => {
  return (<>
    <section>
      <Rect special invert><h2>Representing conditionals graphically</h2></Rect>
      <App title="Our if-then-else node">
        <Image file="th.gif" center={!isSlideShow} />
      </App>
    </section>

    {/* Context */}
    <section>
      <Rect invert><h2>Visual programming with Lynx</h2></Rect>
      <Notes>Lynxtool aims to enable non-programmers to transform complex data by showing them how their data get changed. Instead of having to simulate the effects of code in their heads as programmers do, our editor draws it out for them.</Notes>
      <Image file="msgExample.png" center={!isSlideShow}
        alt="Lynx shows the flow of logic & data"
        style={{ border: '1px solid var(--lynx-grey2)', boxShadow: '10px 10px 0 var(--lynx-light1), 10px 10px 0 1px var(--lynx-grey2)' }} />
      <Notes>You can import CSVs and use our tool to apply computations that you'll normally have to use formulas and pivot tables for. The existing problem with formulas on spreadsheets is they can get confusing once the logic is nested beyond two levels.</Notes>
    </section>
    <section>
      <Rect invert isReading={!isSlideShow}><h2>How do we show this logic flow?</h2></Rect>
      <Notes>A core feature of computation is the ability to do different things under different circumstances. In the example below, let's say you want to tip and message thank you to your courier. And you want to send a different message to them depending on the day they delivered your item.</Notes>
      <Rect isReading={!isSlideShow}>
        <Image file="basicex.png" center={!isSlideShow} alt="Conditional flow, greeting depending on the day" height="400" />
      </Rect>
    </section>

    {/* Problem */}
    <section >
      <Notes>Since this ability is so important, the conditional node was one of the first things our CEO made, months before I joined the team. <br /><br />
        The conditional node is the one on the far right. The top pair of inputs are values to spit back out if the condition is <i>true</i>. The conditional input is the black slot in the middle. Everything below are the values to be returend if the condition is <i>false</i>.  <br /><br />
      </Notes>
      <App title="Existing conditional node">
        <Image file="old_if_impl.png"
          alt="Component before redesign" />
        <Sticky style={{ left: 0, bottom: '-20px', width: '200px' }}>But wait, why there are so many inputs? What are the empty slots for?</Sticky>
      </App>
    </section>
    <section>
      <Notes>So you can pass along multiple values!<br />
        So you can pass along multiple values!
        For example, let's say we tip extra on the weekends. Then we can just add a percentage multiplier to our condition node. While it was nice to have the ability to do that, it was very confusing to our test users.
      </Notes>
      <App title="A simple concept made complex 🤯">
        <Image file="old_impl_multi.png" alt="Component before redesign" />
      </App>
    </section>
    <section>
      <SideBySide>
        <Rect invert special style={vAlignFlex}><h2>Our problem</h2></Rect>
        <Rect special>As a basic element in computation, our conditional node should be simple but was not. It was confusing because there's too many inputs and outputs.</Rect>
      </SideBySide>
      <br />
      <Notes>You may also notice that the condition node looks nothing like the other nodes, that's because in our codebase it was manually drawn. So we defined a design language for our product, this element was difficult to migrate over to the new style guides. And knowing that we wanted to re-design before MVP, we just left it as it was until now.</Notes>
    </section>

    {/* CTA */}
    <section>
      <Rect invert isReading={!isSlideShow}><h3>But we've come a long way since the initial design</h3></Rect>
      <Notes>Our design philosophy has evolved since this node was initially created. During another project which was designing the node insertion dialog, we decided that it will be good to provide examples of how a node can be used. This may not be just a single node, but a cluster of nodes working together to perform a task or data transformation. </Notes>
      <Image file="add_nodes.png" center={!isSlideShow} alt="Can insert a group of nodes" />
      <Notes>We wanted users to learn by example, so instead of just creating single nodes, we plan to allow users to insert a cluster of nodes together. We want to users how to express complex ideas with simple nodes. So simple concepts can stay simple, with more complex expressions made possible via composing the simple components together.<br /><br />This will also enable new users to learn how to construct logic graphs by the examples we provide, which they can edit and play around with.<br /><br />Further down the line, users can also compose their own sub-graphs to share with others. So it saves us time, from having to implement nodes and allow our user base to foster into a community and grow.
      </Notes>
    </section>
    <section>
      <Notes>The original condition node tries to do a lot. But since our product philosophy has changed, we can use the new direction to our advantage. Since we want to keep nodes atomic. Each node should express a programming concept as minimalistic as possible.<br /><br />
        Nodes constructed together can express more complex concepts. And the same can be done with the old Conditional node by combining it with the Pack and Unpack nodes.</Notes>
      <App title="Composing with atomic nodes: Pack and Unpack nodes">
        <Notes>Pack nodes multiple input values to group them into one value to be passed along your graph. Unpack node takes a grouped input and splits out each time to be separately used when necessary.</Notes>
        <Image file="pack-unpack.png" height="400" center={!isSlideShow} />
      </App>
    </section>
    <section>
      <Notes>By combining, Pack, Conditional, and Unpack nodes the abilities of the original conditional nodes can be encapsulated.</Notes>
      <Image file="if_as_packs.png" center={!isSlideShow} alt="same idea can be represented by packs" />
    </section>
    <section>
      <Notes>By utilizing an existing solution, we are able to tackle our biggest challenge in this re-design. Now I can constrain the project to just focus on making a much simpler conditional node, with fewer requirements than the original.<br /></Notes>
      <App title="Pack condition allows multiple values to be passed">
        <Image file="if_as_packs_use.png" alt="same idea can be represented by packs" />
        <Sticky fragment style={{
          bottom: `${-1.5 * sizes.pad}px`,
          right: 0,
          width: '200px'
        }}>With this in mind, we can just focus on making a better 3-input conditional node.</Sticky>
      </App>
      <Notes><br /><br />We still need to at least update this node to conform to our design language. But will be better if we can make it fun and pop!</Notes>
    </section>
    <section>
      <Notes>Since this is a re-design project. Before getting started, I dug up our CEO's old sketches to make sure I am not re-treading dead paths, while coming up with new ideas.</Notes>
      <Rect special otherBG={procreateBGColor}>
        <Sticky style={{ bottom: 0, right: 0 }}>Ideas before my time</Sticky>
        <Image file="prev_ideas.png" center={!isSlideShow} alt="Previous brainstorm ideas" height="500px" />
      </Rect>
      <center></center>
    </section>
    <section>
      <SideBySide>
        <Rect invert style={{ ...vAlignFlex, textAlign: "center" }}><h2>Design @ Lynxtool</h2></Rect>
        <Rect>
          <Notes>We follow a paired-down design process to get us to our MVP. Once have something they can really use, we can get more meaningful user feedback.</Notes>
          <div>
            <p className='small-on-slides'><Dot color={styles.bgGreen} />&nbsp; CEO and I will <em>define the problems</em></p>
            <p className='small-on-slides'><Dot color={styles.bgYellow} />&nbsp;With the whole team, we will <em>brainstorm ideas</em></p>
            <p className='small-on-slides'><Dot color={styles.bgOrange} />&nbsp; Then I refine our best ideas through iteration on <em>visual design</em></p>
          </div>
        </Rect>
      </SideBySide>
    </section>
    <section>
      <Rect special invert><h2>Brainstorm</h2></Rect>
      <Notes>
        Before our brainstorming jam session, I prepare my team with a few big questions, we must answer for the project. And I'll also prepare a variety of solutions or rough ideas on how to answer these questions.
      </Notes>
    </section>
    <section>
      <Rect invert isReading={!isSlideShow}><h3>Ideas shared at brainstorm</h3></Rect>
      <Notes>During our jam session, I lead the discussion by presenting possible solutions to our problems to solve for a project. We used Miro to provide feedback and sketch. And the team also contributes their ideas on how may we solve these problems. Sometimes we may even be able to build on top of each other's ideas if we get inspired during the jam.</Notes>
      <Rect otherBG="white" isReading={!isSlideShow}>
        <Image file="springboard.jpg" center={!isSlideShow} alt="Original brainstorm ideas" height="400" /></Rect>
    </section>
    <section>
      <Rect invert isReading={!isSlideShow}><h4>New ideas that came up during our jam</h4></Rect>
      <Rect otherBG="white" isReading={!isSlideShow}><Image file="other_ideas.jpg" center={!isSlideShow} alt="Team ideas" height="500px" /></Rect>
      <Notes>At the end of the meeting, I take the most popular ideas along with their feedback to refine these notions into solutions.</Notes>
    </section>
    <section>
      <Rect invert special><h3>We chose 4 promising ideas for our new design</h3></Rect>
      <Rect special otherBG="white"><Image file="chosen4.jpg" center={!isSlideShow} alt="our favorites" height="400" />
        <Notes>The top-left is a dial that spins depending on if the result will use the true or false value. It felt very dynamic. <br /><br />
          Our right ones show flow, which is something our links communicate, so we considered reusing the same analogy inside a conditional node. Since uers would have already learn about that visual represention.<br /><br />
          And speaking of re-using existing visual language, the bottom left reuses our iteration selector from our loop node. It indicates that something has been chosen.</Notes></Rect>
    </section>
    <section>
      <App title='Apply our design language to the ideas'>
        <SideBySide>
          <Notes inline>I took ideas and cleaned them up. And tested these design across the node's different states:
            <ul>
              <li><b>No inputs yet</b>, we don't know which path (true or false) our data will take, nor do we know the type of data.</li>
              <li><b>Condition known</b>, we know which path the data will take even if we don't know what type of data we have yet.</li>
              <li><b>Data types known</b>, our paths can be color coded according to the data type.</li>
            </ul>
            We end up deciding to go with the dial idea and further refine it.
          </Notes>
          <Image file="shapes1.png" alt="Try out inner node looks" style={{ width: "300px" }} />
        </SideBySide>
      </App>
    </section>
    <section  >
      <App title="Further variations on our favorite">
        <Notes>I expored a variety of the way the paths on the node can be drawn.</Notes>
        <Image file="shapes2.png" center alt="Refining dial" style={{ height: "50vh" }} />
        <Sticky fragment style={{ width: "200px", left: 0, top: "50%" }}>We found the one where they all meet at 60 degrees to be neatest.</Sticky></App>
    </section>
    <section  >
      <App title="Choosing colors">
        <Image file="colors1.png" alt="Trying out different colors" style={{ height: "50vh" }} center={!isSlideShow} />
        <Notes>I played around with various shades of grey for the dial and path tracks. A surprising find was the dial that is almost-black it looks rather sleek. But also having a light dial will make the data paths stand out more.</Notes>
      </App>
    </section>
    <section>
      <App title="Testing our colors against data types">
        <Notes>So I tested both candidates against data-type colors to see how they will look when filled in. We decided against the black dial because it will have poor contrast for multi-step conditionals, and hypothesize it will be a likely use case.</Notes>
        <Image file="colors2.png" alt="Testing out with types" style={{ height: "50vh" }} center={!isSlideShow} />
      </App>
    </section>
    <section>
      <Notes>An example of a multi step condtional can be something like a decision tree.</Notes>
      <App title="Example: Are you allowed in the party?">
        <Image file="invite_to_party_grey.png" alt="Grey on multi boolean" />
        <Image file="invite_to_party_black.png" alt="Black on multi boolean" />
      </App>
    </section>
    <section>
      <App title="Final designs"><Image file="final.png" alt="Testing out with types" />
        <Notes>So this is the design specification across all possible states.</Notes>
      </App>
    </section>
    <section  >
      <App title="Implementation quirks">
        <Image file="Explaination.png" center height="500px"
          alt="explaining how we figure out where to set the points to draw the lines" />
      </App>
      <Notes>I also ended up being the one to implement our new node design. A fun part of coding here was I got to brush up on trigonometry in order to calculate where to draw the lines' endpoints, so they can align pixel-perfectly when they meet.</Notes>
    </section>
    <section>
      <Rect special invert><h2>User feedback</h2></Rect>
      <SideBySide style={{ height: "300px" }}>
        <Rect special isReading={!isSlideShow}>
          <p style={{ textAlign: "left" }}>👍 responsiveness <br /><br />
            👎 empty state, still confusing</p>
        </Rect>
        <Rect otherBG="var(--lynx-grey1)" special isReading={!isSlideShow}>
          <Image file="final_empty.png" alt="Node before anything is plugged in" />
        </Rect>
      </SideBySide>
      <Notes>While people liked the responsiveness of the conditional node, they weren't fully sure what this node does when they first insert it unto their project.</Notes>
    </section>
    <section>
      <Image file="cond_with_checkbox.png" center alt="Include the checkbox with the default case" />
      <Notes>Piggyback on our idea to include node clusters, if we added a boolean value node to provide more context.</Notes>
    </section>
    <section>
      <App title="Conditions created with a boolean literal">
        {/* TODO fix output color */}
        <Image file="if_in_action.gif" center alt="Toggle checkbox to see node change" />
        <Sticky style={{ width: "300px", bottom: 0 }}>Users can play around to see how this node correspond when the condition changes.</Sticky>
      </App>
    </section>
    <section>
      <Rect special invert><h2>Retrospective</h2></Rect>
      <Rect special>
        <p><Dot color={styles.bgYellow} />&nbsp;The empty state can still be a little confusing. And it was a blind spot that we could have asked users during the static mock up phase.</p>
        <p><Dot color={styles.bgYellow} />&nbsp; If I had known I'll be the one implementing it, I would have spent less time iterating through the states for each approach.</p>
      </Rect>
    </section>
  </>)
};

export default content;