import PropTypes from "prop-types"
import * as React from 'react';

import { StyleSheet, css } from 'aphrodite/no-important';

import {buttonStyle, App, inputStyle} from './sharedStyles';

export const storeKey = "caseStudyAccessKey";

const s = StyleSheet.create({
    form: {
        height: "200px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        "@media (min-width: 768px)": {
            width: "400px",

        }
    },
    label: {
        fontSize: "1.2em"
    },
    errorMessage: {
        opacity: 0,
        color: 'var(--color-dark-primary)',
        transition: "opacity 0.1s"
    },
    showError: {
        opacity: "1"
    }
})

const AccessForm = ({reply}) => {

    const [passwordStatus, setPasswordStatus] = React.useState(0);

    const passwordEl = React.createRef();
    /*
    -1 bad, 0 not submitted, 1 success
    */

    const checkPassword = (evt) => {
        const password = passwordEl.current.value;

        if(!password){
            setPasswordStatus(-1)
            reply(false);
            return;
        }

        const data = { password: password };
    
        fetch('https://alice.builtwithdark.com/folio', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        })
        .then(response => {
            if(!response.ok){
                setPasswordStatus(-1)
            }
            reply(response.ok);
        })
        .catch((error) => {
            setPasswordStatus(-1);
            reply(false);
        });

        evt.preventDefault();
    }

    return (<center>
    <App title="Private case study">
    <form className={css(s.form)}>
        <label htmlFor="password" className={css(s.label)}>To read this, enter the password</label>
        <input type="password" ref={passwordEl} id="password" placeholder="password" className={inputStyle} />
        <button type="button" onClick={checkPassword} className={buttonStyle}>Go</button>
    </form>
    <p className={css(s.errorMessage, passwordStatus === -1 && s.showError)}>Must provide correct password to read this case study</p>

    <p>Interest in reading? <a href="/request">Request access</a>.</p>
        </App>
    </center>)
}

AccessForm.propTypes = {
    reply: PropTypes.func
}

export default AccessForm;