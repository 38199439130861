import * as React from "react"
import { Link } from "react-router-dom"
import { StyleSheet, css } from 'aphrodite/no-important';
import { sizes } from './sharedStyles';

const styles = StyleSheet.create({
  header: {
    color: 'var(--color-dark-grey)',

    padding: `${sizes.pad}px`,
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",

    zIndex: 1,

    "@media (min-width: 768px)": {
      alignItems: "center",
    },

    "@media print": {
      display: "none"
    }
  },
  linksGroup: {
    backgroundColor: "var(--color-light)",
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    marginLeft: 0,
    marginBottom: 0,
    marginRight: `${sizes.pad}px`,
    marginBlockStart: 0,
    fontWeight: "bold",
    fontSize: "1.0em",
    "@media (min-width: 768px)": {
      fontSize: "1.25em",
      flexDirection: "row",
      justifyContent: "flex-end",
    }
  },
  navItem: {
    lineHeight: "2.0em",
    ":nth-child(1n) a": {
      textDecoration: "none"
    },
    "@media (min-width: 768px)": {
      marginLeft: `${2 * sizes.pad}px`,
      ":first-child": {
        marginLeft: 0
      },
    }
  },

  logo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    border: "1px solid var(--color-dark-grey)",
    boxSizing: "border-box",
    // padding: `${sizes.pad}px`,
    width: "70px",
    height: "70px",
    textAlign: "center",
    backgroundColor: "var(--color-light)",
    boxShadow: "4px 4px 0 0 var(--color-primary)",
    transition: 'box-shadow 0.2s, border 0.2s',
    ":hover": {
      textDecoration: "none",
      boxShadow: "0 0 0 6px var(--color-light-grey)",
      border: "2px solid var(--color-primary)",
    },
    ":nth-child(1n) h1": {
      color: 'var(--color-dark-primary)',
      margin: 0,
      fontSize: "30px",
      // textShadow: `2px 2px var(--color-accent)`,
      ":hover": {
        // textShadow: `-1px -1px var(--color-accent)`,
        textDecoration: "none"
      }
    },
    ":nth-child(1n) p": {
      color: 'var(--color-dark-grey)',
      margin: 0,
      marginTop: "4px",
      fontSize: "12px"
    }
  }
})

const NavLink = ({ to, children }) => <li className={css(styles.navItem)}><Link to={to}>{children}</Link></li>;



const Header = () => (
  <header className={css(styles.header)}>
    <Link to="/" className={css(styles.logo)}><h1>Aq</h1><p>Portfolio</p></Link>
    <nav>
      <ul className={css(styles.linksGroup)}>
        <NavLink to="/projects">Projects</NavLink>
        <NavLink to="/resume">Resume</NavLink>
        <NavLink to="/me">About Me</NavLink>
      </ul>
    </nav>
  </header>
)


export default Header
