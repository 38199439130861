import { StyleSheet, css } from 'aphrodite/no-important';
import * as React from "react";

/*
export const colors = Object.freeze({
    accent: "#009B77",
    primary: "#06795e",
    light: "#F3F7F6",
    dark: "#1C2021",
    highlight: "#E0EDEC",
    shadow: "#5D5F6C"
  });
*/

export const sizes = Object.freeze({
  pad: 10,
  sectionSpace: 40
})


const halfPad = `${sizes.pad / 2}px`;
const buttonHover = {
  boxShadow: `0 0 0 ${sizes.pad * 0.7}px var(--color-light-grey)`,
  background: 'var(--color-primary)',
  textDecoration: "none",
};

export const styles = StyleSheet.create({
  button: {
    display: "inline-block",
    padding: `${sizes.pad}px`,
    background: 'var(--color-dark-primary)',
    color: 'var(--color-light) !important',
    border: `var(--color-light) 1px solid`,
    textDecoration: "none",
    fontSize: '1.1em',
    fontWeight: "bold",
    letterSpacing: "1px",
    boxShadow: `${halfPad} ${halfPad} 0 0 var(--color-primary)`,
    transition: 'box-shadow 0.2s',
    ":hover": buttonHover,
    ":focus": buttonHover,
    ":active": buttonHover
  },
  input: {
    display: "inline-block",
    padding: `${sizes.pad}px`,
    backgroundColor: 'var(--color-light)',
    border: '2px solid var(--color-dark-grey)',
    boxShadow: `${halfPad} ${halfPad} 0 var(--color-accent), inset 0 0 0 var(--color-accent)`,
    transition: 'box-shadow 0.2s',
    ':focus': {
      outline: 0,
      border: '2px solid var(--color-dark-accent)',
      boxShadow: `0 0 0 var(--color-accent), inset ${halfPad} ${halfPad} 0 var(--color-accent)`
    }
  },
  sideBySide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    "@media (min-width: 768px)": {
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "stretch",
      flexWrap: "nowrap"
    },

    ":nth-child(1n) > *:first-child": {
      marginBottom: `${sizes.sectionSpace}px`,

      "@media (min-width: 768px)": {
        marginBottom: 0,
        marginRight: `${sizes.sectionSpace}px`
      }
    }
  },

  dot: {
    display: 'inline-block',
    width: '1.0rem',
    height: '1.0rem',
    borderRadius: '0.6rem'
  },
  dotsGroup: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '5rem'
  },

  /* Slides theme rect */
  rect: {
    position: 'relative',
    display: 'block',
    padding: `${sizes.pad}px`,
    marginBottom: `${sizes.sectionSpace}px`,

    ':nth-child(1n) h1': { marginBottom: 0 },
    ':nth-child(1n) h2': { marginBottom: 0 },
    ':nth-child(1n) h3': { marginBottom: 0 },
    ':nth-child(1n) h4': { marginBottom: 0 },

    ':last-child': {
      marginBottom: 0,
      marginRight: 0
    }
  },
  /* App window */

  appWindow: {
    padding: 0
  },
  appTitle: {
    fontFamily: 'var(--font-display)',
    fontSize: '0.8em',
    color: `var(--lynx-grey3)`,
    padding: `${0.5 * sizes.pad}px`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  appTitleText: {
    marginRight: `0.5rem`
  },
  appContent: {
    padding: `${sizes.pad}px`,
    position: 'relative'
  },
  fullBleed: {
    padding: 0,
    lineHeight: 0
  },

  /* Theme Styles */
  bgLight: { backgroundColor: `var(--color-light)` },
  bgLightGrey: { backgroundColor: `var(--color-light-grey)` },
  bgDarkGrey: { backgroundColor: `var(--color-dark-grey)` },
  bgDark: { backgroundColor: `var(--color-dark)` },
  bgAccent: { backgroundColor: `var(--color-accent)` },
  bgPrimary: { backgroundColor: `var(--color-primary)` },

  bgDarkAccent: { backgroundColor: `var(--color-dark-accent)` },


  lightBox: {
    backgroundColor: 'var(--color-light)',
    color: 'var(--color-dark)',
    border: `1px solid var(--color-dark-grey)`
  },
  accentBox: {
    backgroundColor: 'var(--color-accent)',
    color: 'var(--color-dark)',
    border: `1px solid var(--color-dark-grey)`
  },
  primaryBox: {
    backgroundColor: 'var(--color-primary)',
    color: 'var(--color-dark)',
    border: `1px solid var(--color-dark-grey)`
  },
  darkBox: {
    backgroundColor: 'var(--color-dark)',
    color: 'var(--color-light)',
    border: `1px solid var(--color-dark-grey)`
  },

  lightShadow: {
    boxShadow: `${sizes.pad}px ${sizes.pad}px 0 var(--color-light),
        ${sizes.pad}px ${sizes.pad}px 0 1px var(--color-dark-grey)`
  },
  accentShadow: {
    boxShadow: `${sizes.pad}px ${sizes.pad}px 0 var(--color-accent),
        ${sizes.pad}px ${sizes.pad}px 0 1px var(--color-dark-grey)`
  },
  primaryShadow: {
    boxShadow: `${sizes.pad}px ${sizes.pad}px 0 var(--color-primary),
        ${sizes.pad}px ${sizes.pad}px 0 1px var(--color-dark-grey)`
  },

  appTitleBar: {
    fontFamily: 'var(--font-main) !important',
    borderBottom: '1px solid var(--color-dark-grey)',
    color: 'var(--color-dark) !important',
    fontSize: '0.8em'
  }
})

export const buttonStyle = css(styles.button);
export const inputStyle = css(styles.input);

export const SideBySide = ({ children, style }) =>
  (<div className={css(styles.sideBySide)} style={style}>{children}</div>);

export const Dot = ({ color: colorStyle }) => (<span className={css(styles.dot, colorStyle)}></span>);

export const BaseRect = ({
  children,
  style,
}) => {
  return (<div
    className={css(styles.rect)}
    style={style}>{children}</div>);
}

export const Rect = ({ special, invert, children, style }) => {

  const classes = [styles.rect];

  if (invert) {
    classes.push(styles.lightShadow);
    classes.push(special ? styles.primaryBox : styles.accentBox);
  } else {
    classes.push(styles.lightBox);
    classes.push(special ? styles.primaryShadow : styles.accentShadow);
  }

  return (<div
    className={css(...classes)}
    style={style}>{children}</div>);
}

export const BaseSticky = ({ children, style, fragment }) => {
  return (<div className={fragment ? "fragment" : ""} style={{
    display: 'block',
    fontFamily: 'var(--font-body)',
    fontSize: '0.7em',
    padding: `${sizes.pad}px`,
    position: 'absolute',
    zIndex: 50,
    transform: 'rotate(3deg)',
    ...style
  }}>{children}</div>)
}

export const Sticky = ({ children, style }) => (<BaseSticky style={{
  backgroundColor: 'var(--color-light)',
  width: '300px',
  fontSize: "1.0em",
  color: 'var(--color-dark-grey)',
  padding: `${2 * sizes.pad}px`,
  border: '1px solid var(--color-light-grey)',
  boxShadow: '4px 4px 0 0 var(--color-light-grey)',
  ...style
}}>{children}</BaseSticky>);

export const BaseApp = ({ children, title, windowStyles, titleStyles, dotColors, contentStyles = [], fullBleed = false }) => (
  <div className={css(styles.rect, styles.appWindow, ...windowStyles)}>
    <div className={css(styles.appTitle, ...titleStyles)}>
      <span className={css(styles.dotsGroup)}>
        <Dot color={dotColors[0]} />
        <Dot color={dotColors[1]} />
        <Dot color={dotColors[2]} />
      </span>
      <span className={css(styles.appTitleText)}>{title}</span>
    </div>
    <div className={css(styles.appContent, fullBleed && styles.fullBleed, ...contentStyles)}>{children}</div>
  </div>
);

export const App = ({ children, title, contentStyles, fullBleed }) => (<BaseApp
  title={title}
  windowStyles={[styles.lightBox, styles.accentShadow]}
  titleStyles={[styles.bgAccent, styles.appTitleBar]}
  dotColors={[styles.bgDarkAccent, styles.bgLight, styles.bgPrimary]}
  contentStyles={contentStyles}
  fullBleed={fullBleed}
>{children}</BaseApp>);