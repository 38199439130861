import Layout from "../bits/Layout";
import { Rect, App, SideBySide } from '../bits/sharedStyles';

const Page = () => (<Layout still wide>
  <SideBySide>
    <App title="FAB animation with Framer" fullBleed>
      <iframe src="https://player.vimeo.com/video/269727701?h=795add8475&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="362" height="680" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="FAB animation with Framer"></iframe>
    </App>
    <div>
      <Rect>
        <p>As part of a <a href="https://framer.com/" target="_blank" rel="noreferrer">Framer</a> workshop I taught my peers during my time at design bootcamp in <a href="https://www.ironhack.com/en/ux-ui-design" target="_blank" rel="noreferrer">Ironhack, Barcelona.</a></p>
      </Rect>
    </div>
  </SideBySide>
</Layout>);

export default Page;